/*!(C)2019 Rayark Inc. - All Rights Reserved
 * Rayark Confidential
 *
 * NOTICE: The intellectual and technical concepts contained herein are
 * proprietary to or under control of Rayark Inc. and its affiliates.
 * The information herein may be covered by patents, patents in process,
 * and are protected by trade secret or copyright law.
 * You may not disseminate this information or reproduce this material
 * unless otherwise prior agreed by Rayark Inc. in writing.
 */

import React from "react";
import PropTypes from "prop-types";
import FontAwesome from "react-fontawesome";

const BackTopBtn = ({ setClass }) => {
  const scrollTo = (element, to, duration) => {
    if (duration <= 0) {
      return;
    }

    const difference = to - element.scrollTop;
    const perTick = (difference / duration) * 10;

    setTimeout(() => {
      element.scrollTop = element.scrollTop + perTick;
      if (element.scrollTop === to) {
        return;
      }
      scrollTo(element, to, duration - 10);
    }, 10);
  };

  const handleClick = () => {
    scrollTo(document.body, 0, 600);
    scrollTo(document.documentElement, 0, 600);
  };

  return (
    <div className={setClass + " backTopBtn"} onClick={handleClick}>
      <FontAwesome name="angle-up" />
    </div>
  );
};

BackTopBtn.propTypes = {
  setClass: PropTypes.string,
};

export default BackTopBtn;
