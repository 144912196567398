/*!(C)2019 Rayark Inc. - All Rights Reserved
 * Rayark Confidential
 *
 * NOTICE: The intellectual and technical concepts contained herein are
 * proprietary to or under control of Rayark Inc. and its affiliates.
 * The information herein may be covered by patents, patents in process,
 * and are protected by trade secret or copyright law.
 * You may not disseminate this information or reproduce this material
 * unless otherwise prior agreed by Rayark Inc. in writing.
 */

import React, { Component } from "react";
import PropTypes from "prop-types";
import { Scrollbars } from "react-custom-scrollbars";
import { ViewContainer } from "../../components";
import MyGamesRecordsForm from "./MyGamesRecordsForm";
import * as redeemActions from "../../actions/redeem";
import { PAGES } from "../../model/constants";

class MyGamesRecordsView extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.dispatch(redeemActions.getGames());
    this.props.dispatch(redeemActions.getServersAndCharacters());
  }

  renderRecordsForm() {
    const { t, i18n, dispatch, redeem } = this.props;

    const props_records_form = {
      t,
      i18n,
      dispatch,
      games: redeem.games,
      serversAndCharacters: redeem.serversAndCharacters,
    };

    return (
      <div className="admin__panelContent admin--panel_content_right">
        <div className="admin__viewTitle">MY GAMES WITH RAYARK PASS</div>
        <Scrollbars className="admin__viewContent pc">
          <ViewContainer>
            <MyGamesRecordsForm {...props_records_form} />
          </ViewContainer>
          <div className="admin__bottomHint"></div>
        </Scrollbars>
        <div className="admin__viewContent mobile">
          <ViewContainer>
            <MyGamesRecordsForm {...props_records_form} />
          </ViewContainer>
        </div>
      </div>
    );
  }

  render() {
    switch (this.props.page) {
      case PAGES.MY_GAMES_RECORDS:
        return this.renderRecordsForm();
      default:
        return <div> no such view </div>;
    }
  }
}

MyGamesRecordsView.defaultProps = {
  redeem: {},
};

MyGamesRecordsView.propTypes = {
  t: PropTypes.func,
  i18n: PropTypes.object,
  dispatch: PropTypes.func,
  page: PropTypes.string,
  redeem: PropTypes.object,
};

export default MyGamesRecordsView;
