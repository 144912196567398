/*!(C)2019 Rayark Inc. - All Rights Reserved
 * Rayark Confidential
 *
 * NOTICE: The intellectual and technical concepts contained herein are
 * proprietary to or under control of Rayark Inc. and its affiliates.
 * The information herein may be covered by patents, patents in process,
 * and are protected by trade secret or copyright law.
 * You may not disseminate this information or reproduce this material
 * unless otherwise prior agreed by Rayark Inc. in writing.
 */

import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { NAV_OPTIONS } from "../../model/constants";
import BtnBackImg from "../../assets/images/btn_back.png";
import BtnMenuImg from "../../assets/images/btn_menu.png";
import BtnSettingImg from "../../assets/images/btn_setting.png";
class MobileNav extends React.Component {
  onOpenMenu() {
    const target = document.getElementById("mobileMenuPage");
    target.classList.add("active");
  }

  render() {
    const { t, profile, location } = this.props;
    const { pathname, hash } = location;

    const getOptions = () => {
      switch (pathname) {
        case "/logout":
        case "/sign-in-with-twitter":
        case "/waiting":
        case "/verify":
          return NAV_OPTIONS.NONE;
        case "/tos":
        case "/privacy":
        case "/setting":
        case "/forgot":
        case "/reset_password":
          return NAV_OPTIONS.BACK_AND_NONE;
        case "/login":
          return NAV_OPTIONS.BACK_AND_REG;
        case "/register":
          return NAV_OPTIONS.BACK_AND_LOGIN;
        case "/profile":
        case "/redeem":
        case "/my-games":
          return NAV_OPTIONS.MENU_AND_SETTING;
        default:
          return NAV_OPTIONS.MENU_AND_LOGIN;
      }
    };
    const getClassNames = (pathname, hash) => {
      switch (pathname) {
        case "/setting":
          switch (hash) {
            case "#sid":
              return "--underline";
            case "#pwd":
              return "--underline";
            case "#delete-account":
              return "--underline";
            default:
              return "";
          }
        case "/forgot":
          return "--underline";
        default:
          return "";
      }
    };
    const getBackPage = (pathname, hash) => {
      switch (pathname) {
        case "/setting":
          switch (hash) {
            case "#sid":
              return "/setting";
            case "#pwd":
              return "/setting";
            case "#delete-account":
              return "/setting";
            default:
              return "/profile";
          }
        default:
          return "/";
      }
    };

    const options = getOptions(pathname);
    const classNames = getClassNames(pathname, hash);
    const backPage = getBackPage(pathname, hash);

    if (pathname === "/register/mobile") {
      return null;
    } else {
      switch (options) {
        case NAV_OPTIONS.NONE:
          return <div className={"menubar mobile " + classNames}></div>;

        case NAV_OPTIONS.BACK_AND_NONE:
          return (
            <div className={"menubar mobile " + classNames}>
              <Link to={backPage} className="menubar__navLeft">
                <img src={BtnBackImg} />
              </Link>
            </div>
          );

        case NAV_OPTIONS.BACK_AND_REG:
          return (
            <div className={"menubar mobile " + classNames}>
              <Link to="/" className="menubar__navLeft">
                <img src={BtnBackImg} />
              </Link>
              <Link to="/register" className="menubar__navRight nav__button">
                {t("common:register")}
              </Link>
            </div>
          );

        case NAV_OPTIONS.BACK_AND_LOGIN:
          return (
            <div className={"menubar mobile " + classNames}>
              <Link to="/" className="menubar__navLeft">
                <img src={BtnBackImg} />
              </Link>
              <Link to="/login" className="menubar__navRight nav__button">
                {t("common:login")}
              </Link>
            </div>
          );

        case NAV_OPTIONS.MENU_AND_SETTING:
          return (
            <div className={"menubar mobile " + classNames}>
              <a className="menubar__navLeft" onClick={this.onOpenMenu.bind(this)}>
                <img src={BtnMenuImg} />
              </a>
              <Link to="/setting" className="menubar__navRight">
                <img src={BtnSettingImg} />
              </Link>
            </div>
          );

        case NAV_OPTIONS.MENU_AND_LOGIN:
          return (
            <div className={"menubar mobile " + classNames}>
              <a className="menubar__navLeft" onClick={this.onOpenMenu.bind(this)}>
                <img src={BtnMenuImg} />
              </a>
              {profile ? (
                <Link to="/logout" className="menubar__navRight nav__button">
                  {t("common:logout")}
                </Link>
              ) : (
                <Link to="/login" className="menubar__navRight nav__button">
                  {t("common:login")}
                </Link>
              )}
            </div>
          );

        default:
          return <div className={"menubar mobile " + classNames}></div>;
      }
    }
  }
}

function mapStateToProps(state) {
  return {
    profile: state.profile,
  };
}

MobileNav.propTypes = {
  t: PropTypes.func,
  profile: PropTypes.object,
  location: PropTypes.object,
};

export default connect(mapStateToProps)(withTranslation()(MobileNav));
