/*!(C)2019 Rayark Inc. - All Rights Reserved
 * Rayark Confidential
 *
 * NOTICE: The intellectual and technical concepts contained herein are
 * proprietary to or under control of Rayark Inc. and its affiliates.
 * The information herein may be covered by patents, patents in process,
 * and are protected by trade secret or copyright law.
 * You may not disseminate this information or reproduce this material
 * unless otherwise prior agreed by Rayark Inc. in writing.
 */

import React, { Component } from "react";
import PropTypes from "prop-types";
import { Scrollbars } from "react-custom-scrollbars";
import { ViewContainer } from "../../components";
import { GeneralTable } from "../../layouts";
import RedeemForm from "./RedeemForm";
import { getDateTimeFromProtocol, getDateDisplay, getTimeDisplay } from "../../model/helper";
import { PAGES, SEGMENTS } from "../../model/constants";
import * as redeemActions from "../../actions/redeem";

class RedeemView extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.props.dispatch(redeemActions.getGames());
    this.props.dispatch(redeemActions.getServersAndCharacters());
  }

  handleSubmitRedeemData(redeem_data) {
    this.props.dispatch(redeemActions.postRedeem({ ...redeem_data }));
  }

  renderRedeemHistory() {
    const { t, i18n, redeem } = this.props;
    const { games, transactions, serversAndCharacters, rewards } = redeem;
    const lang = i18n.language === "zh" ? "zh-Hant" : i18n.language;

    const renderHistoryTable = () => {
      const columns = [
        {
          key: "time",
          name: "TIME",
          colClassName: "width_5",
          getTitleDisplay: () => (
            <div>
              DATE<span className="small">(GMT+8)</span>
            </div>
          ),
          getValueDisplay: val => {
            return (
              <div>
                <div>{getDateDisplay(getDateTimeFromProtocol(val))}</div>
                <div>{getTimeDisplay(getDateTimeFromProtocol(val))}</div>
              </div>
            );
          },
        },
        {
          key: "reward",
          name: "REWARDS",
          colClassName: "width_7",
          getTitleDisplay: () => <div>REWARDS</div>,
          getValueDisplay: val =>
            rewards && rewards[val]
              ? (rewards[val].names || {})[lang] || (rewards[val].names || {})["en"] || val
              : val,
        },
        {
          key: "game",
          name: "APP",
          colClassName: "width_4",
          getTitleDisplay: () => <div>APP</div>,
          getValueDisplay: val =>
            games && games[val]
              ? (games[val].names || {})[lang] || (games[val].names || {})["en"] || val
              : val,
        },
        {
          key: "server_id",
          name: "SERVER",
          colClassName: "width_4",
          getTitleDisplay: () => <div>SERVER</div>,
          renderContent: row => {
            if (`${row.game}` === "sdorica") {
              // Sdorica
              const servers = serversAndCharacters[row.game] || [];
              const server =
                (servers.find(character => character.server.id === row.server_id) || {}).server ||
                {};
              return (
                (server.names || {})[lang] || (server.names || {})["en"] || row.server_id || "--"
              );
            } else {
              // SoE and VOEZ
              return t("redeem:server_all");
            }
          },
        },
        {
          key: "code",
          name: "S/N",
          colClassName: "width_4",
          getTitleDisplay: () => (
            <div>
              S/N<span className="small">(LAST 5 DIGITS)</span>
            </div>
          ),
          getValueDisplay: val => (val && val.length >= 5 ? val.slice(val.length - 5) : val),
        },
      ];

      return transactions && transactions.length ? (
        <GeneralTable className="redeem__historyTable" source={transactions} columns={columns} />
      ) : (
        <div>{t("redeem:no_data")}</div>
      );
    };

    return (
      <div className="admin__panelContent admin--panel_content_right">
        <div className="admin__viewTitle">Redeem History</div>
        <Scrollbars className="admin__viewContent pc">
          <ViewContainer>{renderHistoryTable()}</ViewContainer>
          <div className="admin__bottomHint"></div>
        </Scrollbars>
        <div className="admin__viewContent mobile">
          <ViewContainer>{renderHistoryTable()}</ViewContainer>
        </div>
      </div>
    );
  }

  renderRedeemForm() {
    const { t, i18n, dispatch, redeem } = this.props;

    const props_redeem_form = {
      t,
      i18n,
      dispatch,
      games: redeem.games,
      serversAndCharacters: redeem.serversAndCharacters,
      onSubmit: this.handleSubmitRedeemData.bind(this),
    };

    return (
      <div className="admin__panelContent admin--panel_content_right">
        <div className="admin__viewTitle">REDEEM STEPS</div>
        <Scrollbars className="admin__viewContent pc">
          <ViewContainer>
            <RedeemForm {...props_redeem_form} />
          </ViewContainer>
          <div className="admin__bottomHint"></div>
        </Scrollbars>
        <div className="admin__viewContent mobile">
          <ViewContainer>
            <RedeemForm {...props_redeem_form} />
          </ViewContainer>
        </div>
      </div>
    );
  }

  render() {
    switch (this.props.page) {
      case PAGES.REDEEM_REDEEM:
        return SEGMENTS[1].hide_for_languages.includes(this.props.i18n.language)
          ? null
          : this.renderRedeemForm();
      case PAGES.REDEEM_HISTORY:
        return this.renderRedeemHistory();
      default:
        return <div> no such view </div>;
    }
  }
}

RedeemView.propTypes = {
  t: PropTypes.func,
  i18n: PropTypes.object,
  dispatch: PropTypes.func,
  page: PropTypes.string,
  redeem: PropTypes.object,
};

export default RedeemView;
