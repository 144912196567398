/*!(C)2019 Rayark Inc. - All Rights Reserved
 * Rayark Confidential
 *
 * NOTICE: The intellectual and technical concepts contained herein are
 * proprietary to or under control of Rayark Inc. and its affiliates.
 * The information herein may be covered by patents, patents in process,
 * and are protected by trade secret or copyright law.
 * You may not disseminate this information or reproduce this material
 * unless otherwise prior agreed by Rayark Inc. in writing.
 */

import config from "../variables";
import { push } from "react-router-redux";
import {
  checkStatus,
  parseJSON,
  getDataWithHeaderToken,
  postDataWithHeaderToken,
  deleteDataWithHeaderToken,
  postFormTo,
} from "../model/network";
import { receiveError } from "./error.js";
import { logout } from "./user";
import { showSimpleModal, hideModal } from "./modal";

export const REQUEST_PROFILE = "REQUEST_PROFILE";
export const RECEIVE_PROFILE = "RECEIVE_PROFILE";
export const UPDATE_SID = "UPDATE_SID";
export const UPDATE_PWD = "UPDATE_PWD";
export const REQUEST_DELETE_ACCOUNT = "REQUEST_DELETE_ACCOUNT";
export const RECEIVE_DELETE_ACCOUNT_SUCCESS = "RECEIVE_DELETE_ACCOUNT_SUCCESS";
export const RECEIVE_DELETE_ACCOUNT_FAILURE = "RECEIVE_DELETE_ACCOUNT_FAILURE";

export const fetchProfile = () => dispatch => {
  dispatch({ type: REQUEST_PROFILE });
  const url = `${config.serverUrl}/player/info`;

  getDataWithHeaderToken(url)
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      if (config.debug.disableUpdateSIDCheck) {
        json.allow_modify_sid = true;
      }
      dispatch({ type: RECEIVE_PROFILE, profile: json });
    })
    .catch(error => {
      dispatch(receiveError("modal", error));
    });
};

export const updateSID = sid => dispatch => {
  let body = {
    identifier: sid,
  };
  if (config.debug.disableUpdateSIDCheck) {
    body.debug = true;
  }

  const url = `${config.serverUrl}/player/sid`;
  postDataWithHeaderToken(url, body)
    .then(checkStatus)
    .then(parseJSON)
    .then(_ => {
      dispatch(fetchProfile());
      dispatch(push("/setting"));
      dispatch(hideModal());
    })
    .catch(error => {
      dispatch(hideModal());
      dispatch(receiveError("UPDATE_SID", error));
    });
};

export const updatePWD = (old_pwd, new_owd) => dispatch => {
  const data = {
    auth_code: "", // the field is required.
    old_password: old_pwd,
    new_password: new_owd,
  };

  const url = `${config.serverUrl}/service/email/secret`;
  fetch(`${config.serverUrl}/csrf_token`, { credentials: "include" })
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      const csrf_token = json.csrf_token;
      const custom_header = {
        "X-CSRF-Token": csrf_token,
      };
      const custom_options = {
        credentials: "include",
      };
      postDataWithHeaderToken(url, data, custom_header, custom_options)
        .then(checkStatus)
        .then(parseJSON)
        .then(() => {
          dispatch(fetchProfile());
          dispatch(
            showSimpleModal({
              className: "",
              title: "form:reset_success_title",
              msg: "form:reset_success",
              onOK: () => dispatch(logout()),
            })
          );
        })
        .catch(error => {
          dispatch(receiveError("UPDATEPWD", error));
        });
    })
    .catch(e => {
      console.error(e);
    });
};

export const bindAccount = target => _dispatch => {
  fetch(`${config.serverUrl}/csrf_token`, { credentials: "include" })
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      const csrf_token = json.csrf_token;
      const access_token = window.localStorage.getItem("accessToken");
      postFormTo(`${config.serverUrl}/service/${target}/bind`, {
        csrf_token: csrf_token,
        access_token: access_token,
      });
    })
    .catch(e => {
      console.error(e);
    });
};

export const deleteAccount = () => dispatch => {
  dispatch({ type: REQUEST_DELETE_ACCOUNT });
  const url = `${config.serverUrl}/player`;
  deleteDataWithHeaderToken(url)
    .then(checkStatus)
    .then(parseJSON)
    .then(_ => {
      dispatch({ type: RECEIVE_DELETE_ACCOUNT_SUCCESS });
      localStorage.setItem("accessToken", "");
    })
    .catch(error => {
      dispatch({ type: RECEIVE_DELETE_ACCOUNT_FAILURE });
      dispatch(receiveError("modal", error));
    });
};
