/*!(C)2019 Rayark Inc. - All Rights Reserved
 * Rayark Confidential
 *
 * NOTICE: The intellectual and technical concepts contained herein are
 * proprietary to or under control of Rayark Inc. and its affiliates.
 * The information herein may be covered by patents, patents in process,
 * and are protected by trade secret or copyright law.
 * You may not disseminate this information or reproduce this material
 * unless otherwise prior agreed by Rayark Inc. in writing.
 */

import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { hideModal } from "../../actions/modal";

class ErrorInnerModal extends React.Component {
  handleClose() {
    const { dispatch } = this.props;
    dispatch(hideModal());
  }

  render() {
    const { msg, t } = this.props;

    let returnBtn = (
      <div className="action" onClick={this.handleClose.bind(this)}>
        <div className="button subBtn">{t("form:ok")}</div>
      </div>
    );

    if (msg === "SESSION_EXPIRED" || msg === "INVALID_ARGUMENT" || msg === "AUTH_CODE_EXPIRED") {
      returnBtn = (
        <div className="action">
          <Link className="button subBtn returnLogin" to="/logout">
            {t("form:ok")}
          </Link>
        </div>
      );
    }

    return (
      <div className="resultContent result_Error">
        <div className="errMSG">
          {t(`error:${msg}`) === msg ? t("error:GENERAL_ERROR") : t(`error:${msg}`)}
        </div>
        {returnBtn}
      </div>
    );
  }
}

ErrorInnerModal.propTypes = {
  t: PropTypes.func,
  dispatch: PropTypes.func,
  msg: PropTypes.string,
};

export default ErrorInnerModal;
