/*!(C)2019 Rayark Inc. - All Rights Reserved
 * Rayark Confidential
 *
 * NOTICE: The intellectual and technical concepts contained herein are
 * proprietary to or under control of Rayark Inc. and its affiliates.
 * The information herein may be covered by patents, patents in process,
 * and are protected by trade secret or copyright law.
 * You may not disseminate this information or reproduce this material
 * unless otherwise prior agreed by Rayark Inc. in writing.
 */

import { SHOW_MODAL, HIDE_MODAL } from "../actions/modal";

import { SEARCH_USER } from "../actions/friends";

const init_state = {
  type: null,
  props: {},
};

export default function modalReducer(state = init_state, action) {
  if (action.type === SHOW_MODAL) {
    return {
      type: action.modalType,
      props: action.props,
    };
  }

  if (action.type === SEARCH_USER) {
    return {
      type: "SEARCH_RESULT",
      props: {},
    };
  }

  if (action.type === HIDE_MODAL) {
    return init_state;
  }

  return state;
}
