/*!(C)2019 Rayark Inc. - All Rights Reserved
 * Rayark Confidential
 *
 * NOTICE: The intellectual and technical concepts contained herein are
 * proprietary to or under control of Rayark Inc. and its affiliates.
 * The information herein may be covered by patents, patents in process,
 * and are protected by trade secret or copyright law.
 * You may not disseminate this information or reproduce this material
 * unless otherwise prior agreed by Rayark Inc. in writing.
 */

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

import * as translationZH from "./locales/zh.json";
import * as translationEN from "./locales/en.json";
import * as translationJA from "./locales/ja.json";
import * as translationKO from "./locales/ko.json";
import * as translationTH from "./locales/th.json";
import * as translationVI from "./locales/vi.json";
import * as translationES from "./locales/es.json";
import * as translationPT from "./locales/pt.json";
import * as translationRU from "./locales/ru.json";
import * as translationDE from "./locales/de.json";
import * as translationFR from "./locales/fr.json";

const options = {
  // order and from where user language should be detected
  order: ["localStorage", "navigator"],

  // keys or params to lookup language from
  lookupLocalStorage: "i18nextLng",

  // cache user language on
  // caches: ['localStorage'],
  // excludeCacheFor: ['cimode'] // languages to not persist (cookie, localStorage)
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: options,
    resources: {
      en: translationEN.default,
      zh: translationZH.default,
      ja: translationJA.default,
      ko: translationKO.default,
      th: translationTH.default,
      vi: translationVI.default,
      es: translationES.default,
      pt: translationPT.default,
      ru: translationRU.default,
      de: translationDE.default,
      fr: translationFR.default,
    },
    whitelist: ["en", "zh", "ja", "ko", "th", "vi", "es", "pt", "ru", "de", "fr"], // the language codes follow the ISO 639-1
    fallbackLng: ["en", "zh"],
    ns: ["common", "index", "profile", "redeem", "error", "form", "faq", "block"],
    defaultNS: "common",
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for React as it escapes by default
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
