/*!(C)2019 Rayark Inc. - All Rights Reserved
 * Rayark Confidential
 *
 * NOTICE: The intellectual and technical concepts contained herein are
 * proprietary to or under control of Rayark Inc. and its affiliates.
 * The information herein may be covered by patents, patents in process,
 * and are protected by trade secret or copyright law.
 * You may not disseminate this information or reproduce this material
 * unless otherwise prior agreed by Rayark Inc. in writing.
 */

import React from "react";
import PropTypes from "prop-types";

function GeneralTable({ source, columns, className = "" }) {
  return (
    <table className={className}>
      <thead>
        <tr>
          {columns.map(col => {
            const title =
              typeof col.getTitleDisplay === "function" ? col.getTitleDisplay() : col.name;
            return (
              <th key={col.key} className={`${col.colClassName || ""}`}>
                {title}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {source.map((row, index) => {
          return (
            <tr key={index}>
              {columns.map(col => {
                const getContent = (row, col) => {
                  if (typeof col.renderContent === "function") {
                    return col.renderContent(row);
                  } else {
                    const value = row[col.key];
                    return typeof col.getTitleDisplay === "function"
                      ? col.getValueDisplay(value)
                      : value;
                  }
                };
                return <td key={`row_content_${col.key}`}>{getContent(row, col)}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

GeneralTable.propTypes = {
  source: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string,
};

export default GeneralTable;
