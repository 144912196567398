/*!(C)2019 Rayark Inc. - All Rights Reserved
 * Rayark Confidential
 *
 * NOTICE: The intellectual and technical concepts contained herein are
 * proprietary to or under control of Rayark Inc. and its affiliates.
 * The information herein may be covered by patents, patents in process,
 * and are protected by trade secret or copyright law.
 * You may not disseminate this information or reproduce this material
 * unless otherwise prior agreed by Rayark Inc. in writing.
 */

import React from "react";
import PropTypes from "prop-types";
import { push } from "react-router-redux";
import { Link } from "react-router-dom";
import FontAwesome from "react-fontawesome";
import ClipboardButton from "react-clipboard.js";
import { RedeemSegment, FriendSegment, MyGamesSegment } from "..";
import { getSegmentDataModel } from "../../model/helper";
import { PAGES, SEGMENT_KEY, SEGMENTS } from "../../model/constants";
import * as friendActions from "../../actions/friends";
import * as modalActions from "../../actions/modal";
import RayarkPassLogoSmall from "../../assets/images/rayarkpassLOGO_small.png";

import menuFriendsIcon from "../../assets/images/page_friends.png";
import menuRedeemIcon from "../../assets/images/page_redeem.png";

import btn_addImg from "../../assets/images/btn_add.png";
import btn_clone from "../../assets/images/btn_clone.png";

function Avatar({ avatarURL = "" }) {
  const style = {
    background: `url(${avatarURL}) 50% 50% no-repeat`,
    backgroundSize: "cover",
  };

  return (
    <div className="avatar">
      <div className="defaultAvatar" />
      {avatarURL ? <div className="avatarImg" style={style}></div> : null}
    </div>
  );
}

Avatar.propTypes = {
  avatarURL: PropTypes.string,
};

function UserSID({ SID, children }) {
  let sid = SID;
  sid = sid.split("#");

  return (
    <div className="userSID sidName">
      <span className="PR_Custom">{sid[0]}</span>
      <span className="PR_num">{`#${sid[1]}`}</span>
      {children}
    </div>
  );
}

UserSID.propTypes = {
  SID: PropTypes.string,
  children: PropTypes.node,
};

class ProfileSID extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      newSID: "",
    };
  }

  render() {
    const { profile, children } = this.props;
    if (!profile) {
      return null;
    }
    const [sidCustom, sidNum] = profile && profile.sid ? profile.sid.split("#") : ["", ""];

    return (
      <div className="sid">
        <div className="sidtext">
          <span className="PR_Custom">{sidCustom}</span>
          <span className="PR_num">{`#${sidNum}`}</span>
          <CloneBtn profile={profile} dispatch={this.props.dispatch} />
        </div>
        {children}
      </div>
    );
  }
}

ProfileSID.propTypes = {
  profile: PropTypes.object,
  children: PropTypes.node,
  dispatch: PropTypes.func,
};

function UserProfile({ t, dispatch, mobileHide, profile, showLogoutBtnMobile }) {
  return profile && profile.sid ? (
    <div className={`admin__profileHeader ${mobileHide ? "mobileHide" : ""}`}>
      <div className="person">
        <Avatar avatarURL={profile.avatar} />
        <ProfileSID profile={profile} dispatch={dispatch} />
      </div>
      {showLogoutBtnMobile && (
        <Link to="/logout" className="admin__LogoutBtnMobile mobile">
          {t("common:logout")}
        </Link>
      )}
    </div>
  ) : null;
}

UserProfile.propTypes = {
  t: PropTypes.func,
  profile: PropTypes.object,
  mobileHide: PropTypes.bool,
  dispatch: PropTypes.func,
  showLogoutBtnMobile: PropTypes.bool,
};

function SegmentControlView({
  t,
  i18n,
  dispatch,
  app = {},
  redeem,
  friends,
  profile,
  handleSwitchSegment,
}) {
  const segment = app.segment || SEGMENT_KEY.FRIEND;

  const handleClickSegment = segment_key => {
    const segment_found = SEGMENTS.find(seg => seg.key === segment_key);
    if (segment_found) {
      dispatch(push(segment_found.path));
      handleSwitchSegment(segment_key);
    }
  };

  const getVisibleSegments = () => {
    const segments_all = [
      {
        key: SEGMENT_KEY.FRIEND,
        icon_src: menuFriendsIcon,
        icon_text: "Friends",
        className: "menu_friends",
        onClickKey: handleClickSegment.bind(this),
        data_model: getSegmentDataModel(SEGMENT_KEY.FRIEND),
      },
      {
        key: SEGMENT_KEY.REDEEM,
        icon_src: menuRedeemIcon,
        icon_text: "Redeem",
        className: "menu_redeem",
        onClickKey: handleClickSegment.bind(this),
        data_model: getSegmentDataModel(SEGMENT_KEY.REDEEM),
      },
      {
        key: SEGMENT_KEY.MY_GAMES,
        icon_src: menuFriendsIcon,
        icon_text: "My Games",
        className: "menu_my_games",
        onClickKey: handleClickSegment.bind(this),
        data_model: getSegmentDataModel(SEGMENT_KEY.MY_GAMES),
      },
    ];

    return segments_all.filter(seg => {
      const hide_for_languages = seg.data_model.hide_for_languages || [];
      return i18n.language && !hide_for_languages.includes(i18n.language);
    });
  };

  const renderSegmentContent = () => {
    const props_segment_friend = { app, friends, profile, dispatch, t };
    const props_segment_redeem = { app, redeem, dispatch, t, i18n };
    const props_segment_my_games = { app, redeem, dispatch, t, i18n };

    switch (segment) {
      case SEGMENT_KEY.FRIEND:
        return <FriendSegment {...props_segment_friend} />;
      case SEGMENT_KEY.REDEEM:
        return <RedeemSegment {...props_segment_redeem} />;
      case SEGMENT_KEY.MY_GAMES:
        return <MyGamesSegment {...props_segment_my_games} />;
      default:
        return null;
    }
  };

  return (
    <div className="segmentControlView">
      <div className="admin__profileMenu pc">
        {getVisibleSegments().map((seg, index) => {
          const is_active = segment === seg.key;
          return (
            <SegmentIcon
              key={index}
              className={`${seg.className} ${is_active ? "active" : ""}`}
              icon_key={seg.key}
              icon_src={seg.icon_src}
              icon_text={seg.icon_text}
              onClickKey={seg.onClickKey}
            />
          );
        })}
      </div>
      {renderSegmentContent()}
    </div>
  );
}

SegmentControlView.propTypes = {
  t: PropTypes.func,
  i18n: PropTypes.object,
  dispatch: PropTypes.func,
  app: PropTypes.object,
  redeem: PropTypes.object,
  friends: PropTypes.object,
  profile: PropTypes.object,
  handleSwitchSegment: PropTypes.func,
};

class MobileSearchUser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: "",
      is_ios11: false,
    };
    this.fields = {};
  }

  componentDidMount() {
    const ua = navigator.userAgent;
    const iOS = /iPad|iPhone|iPod/.test(ua);
    const iOS11 = /OS 11_0|OS 11_1|OS 11_2/.test(ua);

    if (iOS && iOS11) {
      this.setState({
        is_ios11: true,
      });
    }
  }

  handleSearch() {
    const sid = document.getElementById("search_sid_mobile").value;
    if (!sid) {
      return;
    }
    this.props.dispatch(friendActions.searchUser(sid));
    this.handleClose();
  }

  showSearchPage() {
    /* fix ios 11 modal bug */
    if (this.state.is_ios11) document.getElementById("container").classList.add("iosBugFixCaret");
    this.setState({ active: "active" });
  }

  handleClose() {
    if (this.state.is_ios11)
      document.getElementById("container").classList.remove("iosBugFixCaret");
    this.setState({ active: "" });
  }

  getSearchModalTitle() {
    const { app } = this.props;
    let title = "";
    switch (app.page) {
      case PAGES.FOLLOWING:
        title = "profile:search_title";
        break;
      default:
        break;
    }
    return title;
  }

  renderSearchComponent() {
    const { t } = this.props;
    const { active, is_ios11 } = this.state;
    const search_modal_title = this.getSearchModalTitle();

    return (
      <div className="mobile">
        <div className="mobileSearchBtn" onClick={this.showSearchPage.bind(this)}>
          <img src={btn_addImg} />
        </div>
        <div
          className={`resultBox mobileSearchPage ${active} ${
            active && is_ios11 ? "iosBugFixCaret" : ""
          }`}>
          <div className="resultBoxBack">
            <div className="resultContent result_search">
              <div className="title">{t(search_modal_title)}</div>
              <div className="searchBar">
                <input
                  type="search"
                  id="search_sid_mobile"
                  placeholder={t("profile:search_placeholder")}
                  ref={ref => (this.fields.search_sid = ref)}
                  name="sid"
                />
                <FontAwesome name="search" onClick={this.handleSearch.bind(this)} />
              </div>
              <div className="button" onClick={this.handleSearch.bind(this)}>
                {t("profile:search")}
              </div>
              <div className="action">
                <div className="button subBtn" onClick={this.handleClose.bind(this)}>
                  {t("form:cancel")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { app } = this.props;
    switch (app.page) {
      case PAGES.FOLLOWERS:
      case PAGES.REDEEM_REDEEM:
      case PAGES.REDEEM_HISTORY:
        return null;
      default:
        return this.renderSearchComponent();
    }
  }
}

MobileSearchUser.propTypes = {
  t: PropTypes.func,
  dispatch: PropTypes.func,
  app: PropTypes.object,
};

function SegmentIcon({ className, icon_key, icon_src, icon_text, onClickKey }) {
  const handleClickKey = key => () => {
    onClickKey(key);
  };

  return (
    <div className={`segmentIcon ${className}`} onClick={handleClickKey(icon_key).bind(this)}>
      <img src={icon_src} />
      <div className="text">{icon_text}</div>
    </div>
  );
}

SegmentIcon.propTypes = {
  className: PropTypes.string,
  icon_key: PropTypes.string,
  icon_src: PropTypes.string,
  icon_text: PropTypes.string,
  onClickKey: PropTypes.func,
};

function ViewContainer({ className, children }) {
  return <div className={`viewContainer ${className || ""}`}>{children}</div>;
}

ViewContainer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

function LeftNavPanel({ navs, active_key, show_badge, small_icon, show_icon, children }) {
  const renderNormalIcons = () => {
    const n_nav = navs.length;
    return navs.map(navitem => {
      const is_active = navitem.key === active_key;
      return (
        <div
          className={`admin__viewMenuIcon ${is_active ? "active" : ""} partition_${n_nav}`}
          key={navitem.key}
          onClick={navitem.onClick}>
          <div>
            {show_icon ? (
              <span className="icon">
                <img
                  style={navitem.icon.style}
                  src={is_active ? navitem.icon.on : navitem.icon.off}
                />
              </span>
            ) : null}
            {show_badge ? <span className="badge">{navitem.badge}</span> : null}
            <span className="mobile">{navitem.mobile_name || navitem.name}</span>
          </div>
        </div>
      );
    });
  };

  const renderSmallIcons = () => {
    const n_nav = navs.length;
    return navs.map(navitem => {
      const is_active = navitem.key === active_key;
      return (
        <div
          className={`admin__viewMenuIcon small ${is_active ? "active" : ""} partition_${n_nav}`}
          key={navitem.key}
          onClick={navitem.onClick}>
          <div>
            {show_icon ? (
              <span className="icon">
                <img
                  style={navitem.icon.style}
                  src={is_active ? navitem.icon.on : navitem.icon.off}
                />
              </span>
            ) : null}
            <span className="name">{navitem.name}</span>
            <span className="mobile tab__title--games">{navitem.mobile_name || navitem.name}</span>
          </div>
        </div>
      );
    });
  };

  return (
    <div className="admin__leftNavPanel" id="admin__leftNavPanel">
      <div className="admin__leftNavBack" />
      <div className="admin__panelViewMenu admin--panel_content_left">
        {small_icon ? renderSmallIcons() : renderNormalIcons()}
      </div>
      {children}
    </div>
  );
}

LeftNavPanel.propTypes = {
  navs: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      name: PropTypes.string,
      icon: PropTypes.shape({
        on: PropTypes.string,
        off: PropTypes.string,
      }),
      badge: PropTypes.node,
      onClick: PropTypes.func,
    })
  ),
  active_key: PropTypes.string,
  show_badge: PropTypes.bool,
  small_icon: PropTypes.bool,
  show_icon: PropTypes.bool,
  mobile_name: PropTypes.string,
  children: PropTypes.node,
};

function CloneBtn({ dispatch, profile }) {
  const cloneDone = () => {
    dispatch(modalActions.showCloneDoneModal());
  };

  let mySID = profile ? profile.sid : "";

  return (
    <div className="cloneBtn">
      <ClipboardButton data-clipboard-text={mySID} onSuccess={cloneDone.bind(this)}>
        <img src={btn_clone} />
      </ClipboardButton>
    </div>
  );
}

CloneBtn.propTypes = {
  dispatch: PropTypes.func,
  profile: PropTypes.object,
};

function AdminContainer({ isExpandable, isFlexInLandscape, showLogoMobile, children }) {
  return (
    <div className="admin__profileBlock">
      <div className="admin__background"></div>
      {showLogoMobile && (
        <a href="/" className="admin__logo mobile">
          <img src={RayarkPassLogoSmall} />
        </a>
      )}
      <div
        className={`admin__profileBox ${isExpandable ? "--extendable" : ""} ${
          isFlexInLandscape ? "--flexInLandscape" : ""
        }`}>
        <div className="admin__fakeBack"></div>
        {children}
      </div>
    </div>
  );
}
AdminContainer.propTypes = {
  isExpandable: PropTypes.bool,
  isFlexInLandscape: PropTypes.bool,
  showLogoMobile: PropTypes.bool,
  children: PropTypes.node,
};

export {
  SegmentIcon,
  CloneBtn,
  UserProfile,
  Avatar,
  UserSID,
  ProfileSID,
  LeftNavPanel,
  ViewContainer,
  SegmentControlView,
  MobileSearchUser,
  AdminContainer,
};
