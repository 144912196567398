/*!(C)2019 Rayark Inc. - All Rights Reserved
 * Rayark Confidential
 *
 * NOTICE: The intellectual and technical concepts contained herein are
 * proprietary to or under control of Rayark Inc. and its affiliates.
 * The information herein may be covered by patents, patents in process,
 * and are protected by trade secret or copyright law.
 * You may not disseminate this information or reproduce this material
 * unless otherwise prior agreed by Rayark Inc. in writing.
 */

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { hot } from "react-hot-loader/root";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Nav, Footer } from "../layouts";
import { withTranslation } from "react-i18next";
import { loadAccessToken } from "../actions/user";

function App({ dispatch, t, i18n, location, children }) {
  const { pathname } = location;

  useEffect(() => {
    dispatch(loadAccessToken());
  }, [dispatch]);

  const target = document.getElementById("mobileMenuPage");
  if (target) {
    target.classList.remove("active");
  }

  return (
    <div id="backTopDom" lang={i18n.language}>
      <Nav dispatch={dispatch} showLogin={pathname === "/"} location={location} />
      {children}
      <Footer setBackground={pathname === "/"} t={t} lang={i18n.language} />
    </div>
  );
}

App.propTypes = {
  dispatch: PropTypes.func,
  t: PropTypes.func,
  i18n: PropTypes.object,
  location: PropTypes.object,
  children: PropTypes.node,
};

// Mark your root component as hot-exported
export default hot(withRouter(connect()(withTranslation()(App))));
