/*!(C)2022 Rayark Inc. - All Rights Reserved
 * Rayark Confidential
 *
 * NOTICE: The intellectual and technical concepts contained herein are
 * proprietary to or under control of Rayark Inc. and its affiliates.
 * The information herein may be covered by patents, patents in process,
 * and are protected by trade secret or copyright law.
 * You may not disseminate this information or reproduce this material
 * unless otherwise prior agreed by Rayark Inc. in writing.
 */

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import checked from "../../assets/images/checked-checkbox-dark.png"
import unchecked from "../../assets/images/unchecked-checkbox-dark.png"
import {
  Dialog,
  DialogSubtitle,
  DialogContent,
  DialogFooter,
} from "../../layouts";
import { Loading } from "../../components";
import { deleteAccount } from "../../actions/profile"

const CheckedBoxLink = () => <a target="_blank" rel="noopener noreferrer" href="https://icons8.com/icon/924/checked-checkbox">Checked</a>
const UncheckedBoxLink = () => <a target="_blank" rel="noopener noreferrer" href="https://icons8.com/icon/923/unchecked-checkbox">Unchecked</a>
const Icons8Link = () => <> icon by <a target="_blank" rel="noopener noreferrer" href="https://icons8.com"> Icons8</a></>

const CheckList = ({ dispatch }) => {
  const { t } = useTranslation()
  const confirmList = t("profile:delete_check_list").split("\n")

  const [checkList, setCheckList] = useState(confirmList.map(_ => false))

  const allChecked = !checkList.includes(false)

  const handleDeleteAccount = e => {
    e.preventDefault();

    if (allChecked) {
      dispatch(deleteAccount());
    }
  };

  return (
    <Dialog className="setting__settingBox setting_subPage">
      <DialogSubtitle subtitle={t("profile:delete_account")} />
      <DialogContent className="setting__settingBlock">
        <div className="checkList">
          {confirmList.map((check, index) => (
            <div
              key={`check-${index}`}
              onClick={() => setCheckList(pre => {
                const next = [...pre]
                next.splice(index, 1, !next[index])
                return next
              })}>
              <img className="checkbox" src={checkList[index] ? checked : unchecked} />
              <span>{check}</span>
            </div>
          ))}
        </div>

        {!allChecked && <span className="waringMessage">{t("profile:finish_check")}</span>}
      </DialogContent>
      <DialogFooter>
        <div className="setting__buttonRow">
          <div className={`button ${allChecked ? '' : 'disabled'}`} onClick={handleDeleteAccount}>
            {t("form:ok")}
          </div>
          <Link to="/setting" className="button subBtn">
            {t("form:cancel")}
          </Link>
        </div>
      </DialogFooter>
      <span className="credit">
        <CheckedBoxLink /> and  <UncheckedBoxLink /> <Icons8Link />
      </span>
    </Dialog>
  );
};

CheckList.propTypes = {
  dispatch: PropTypes.func,
};

const DeleteSuccess = () => {
  const { t } = useTranslation()
  const tips = t("profile:after_delete_tips").split("\n")
  return (
    <Dialog className="setting__settingBox setting_subPage">
      <DialogSubtitle subtitle={t("profile:delete_account")} />
      <DialogContent className="setting__settingBlock">
        <div className="tips">
          {tips.map((tip, index) => (
            <div key={`tip-${index}`} >
              <span>{tip}</span>
            </div>
          ))}
        </div>
      </DialogContent>
      <DialogFooter>
        <div className="setting__buttonRow">
          <div className="button" onClick={() => window.location.href = '/'}>
            {t("form:ok")}
          </div>
        </div>
      </DialogFooter>
    </Dialog>
  );
}

const DeleteAccount = ({ dispatch, profile }) => {
  const { loading, success } = profile.deleteAccount || {}

  return (
    loading
      ? <Loading />
      : success
        ? <DeleteSuccess />
        : <CheckList dispatch={dispatch} />
  );
};

DeleteAccount.propTypes = {
  dispatch: PropTypes.func,
  profile: PropTypes.object,
};

export default DeleteAccount;
