/*!(C)2019 Rayark Inc. - All Rights Reserved
 * Rayark Confidential
 *
 * NOTICE: The intellectual and technical concepts contained herein are
 * proprietary to or under control of Rayark Inc. and its affiliates.
 * The information herein may be covered by patents, patents in process,
 * and are protected by trade secret or copyright law.
 * You may not disseminate this information or reproduce this material
 * unless otherwise prior agreed by Rayark Inc. in writing.
 */

import React from "react";
import PropTypes from "prop-types";
import { hideModal } from "../../actions/modal";

function SimpleMessageModal({ t, dispatch, title, msg, className = "", onOK }) {
  const handleClose = () => {
    dispatch(hideModal());
    if (typeof onOK === "function") onOK();
  };

  return (
    <div className={`resultContent ${className}`}>
      <div className="title">{t(title)}</div>
      <div className="describe" dangerouslySetInnerHTML={{ __html: t(msg) }} />
      <div className="action">
        <div className="button" onClick={handleClose}>
          {t("form:ok")}
        </div>
      </div>
    </div>
  );
}

SimpleMessageModal.propTypes = {
  t: PropTypes.func,
  dispatch: PropTypes.func,
  title: PropTypes.string,
  msg: PropTypes.string,
  className: PropTypes.string,
  onOK: PropTypes.func,
};

export default SimpleMessageModal;
