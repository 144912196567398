/*!(C)2019 Rayark Inc. - All Rights Reserved
 * Rayark Confidential
 *
 * NOTICE: The intellectual and technical concepts contained herein are
 * proprietary to or under control of Rayark Inc. and its affiliates.
 * The information herein may be covered by patents, patents in process,
 * and are protected by trade secret or copyright law.
 * You may not disseminate this information or reproduce this material
 * unless otherwise prior agreed by Rayark Inc. in writing.
 */

import React, { useState } from "react";
import PropTypes from "prop-types";
import { TextInput, DropdownInput } from "../../layouts";

function MyGamesRecordsForm({ t, i18n, games, serversAndCharacters }) {
  const [gameId, setGameId] = useState(null);
  const [serverId, setServerId] = useState(null);

  const handleSetGameId = value => {
    setGameId(value);

    // If only has one server, select it.
    const servers = serversAndCharacters[value];
    if (servers && servers.length === 1) {
      setServerId(servers[0].server.id);
    } else {
      setServerId(null);
    }
  };

  const records = serversAndCharacters[gameId] || [];
  const selected_record = records.find(record => record.server.id === serverId) || {};
  const lang = i18n.language === "zh" ? "zh-Hant" : i18n.language;

  let gameOptions = [];
  if (games && Object.keys(games).length) {
    Object.keys(games).forEach(id => {
      if (games[id]) {
        gameOptions.push({
          value: games[id].id,
          name: `${(games[id].names || {})[lang] || (games[id].names || {})["en"]}`,
        });
      }
    });
  }

  let serverOptions = [];
  if (records && records.length) {
    records.forEach(record => {
      const { server } = record;
      const { id } = server || {};

      const getServerName = () => {
        const server_name = (server.names || {})[lang] || (server.names || {})["en"];
        return server_name;
      };

      if (server && id !== null && id !== undefined) {
        serverOptions.push({
          value: id,
          name: getServerName(),
        });
      }
    });
  }

  return (
    <div>
      <div className="admin__mobileViewTitle mobile">MY GAMES</div>
      <form className="admin__form">
        {/* Select Game */}
        <div className="admin__form-group">
          {games && Object.keys(games).length ? (
            <DropdownInput
              id="game-select"
              renderAsSelect={true}
              title={gameId ? games?.[gameId]?.names?.[lang] ?? gameId : ""}
              value={gameId}
              placeholder={t("form:select_my_games")}
              onChange={handleSetGameId}
              options={gameOptions}
            />
          ) : (
            <DropdownInput disabled={true} title={t("redeem:placeholder_no_game")} />
          )}
        </div>

        {/* Select Server */}
        <div className="admin__form-group">
          {records && records.length ? (
            <DropdownInput
              id="server-select"
              renderAsSelect={true}
              title={
                serverId !== null && serverId !== undefined && selected_record.server
                  ? `${(selected_record.server.names || {})[lang]}`
                  : null
              }
              value={serverId}
              disabled={!gameId}
              placeholder={t("redeem:placeholder_server")}
              onChange={setServerId}
              options={serverOptions}
            />
          ) : (
            <DropdownInput disabled={true} title={t("redeem:placeholder_no_player")} />
          )}
        </div>
        <div className="admin__spacer" />

        {/* Character Name */}
        <div className="admin__form-group">
          <TextInput
            placeholder={t("redeem:placeholder_character")}
            value={selected_record.character_name ? selected_record.character_name : ""}
            disabled={true}
          />
        </div>
      </form>
    </div>
  );
}

MyGamesRecordsForm.propTypes = {
  t: PropTypes.func,
  i18n: PropTypes.object,
  dispatch: PropTypes.func,
  games: PropTypes.object,
  serversAndCharacters: PropTypes.object,
};

export default MyGamesRecordsForm;
