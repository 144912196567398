/*!(C)2019 Rayark Inc. - All Rights Reserved
 * Rayark Confidential
 *
 * NOTICE: The intellectual and technical concepts contained herein are
 * proprietary to or under control of Rayark Inc. and its affiliates.
 * The information herein may be covered by patents, patents in process,
 * and are protected by trade secret or copyright law.
 * You may not disseminate this information or reproduce this material
 * unless otherwise prior agreed by Rayark Inc. in writing.
 */

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { MobileNav, MobileMenu } from "..";
import { UserSID } from "../../components/AdminComponents/AdminComponents";
import { LANGUAGES, LANG_DISPLAY } from "../../model/constants";
import { setLanguage } from "../../actions/user";
import RayarkPassLogoSmall from "../../assets/images/rayarkpassLOGO_small.png";
import FontAwesome from "react-fontawesome";

import "./nav.scss";
import "./nav_mobile.scss";

function Nav({ dispatch, t, i18n, location, profile, showLogin }) {
  const [menuState, setMenuState] = useState("");
  const SID = profile && profile.sid ? <UserSID SID={profile.sid} /> : "";
  const show = location.pathname === "/forgot" ? "forgotPage" : showLogin ? "" : " hideLogin";

  return (
    <div className="nav">
      <nav className={`nav__loginBar ${profile ? "nav--login_true" : "nav--login_false"} ${show}`}>
        <div className="nav__logo pc">
          <a href="/" />
          <img src={RayarkPassLogoSmall} />
        </div>
        <div className="menubar pc">
          <div className="menubar__block">
            <Link className="menubar__btn menubar__loginBtn" to="/login">
              {t("common:login")}
            </Link>
            <span className="menubar__divider menubar__btn menubar__loginBtn"> / </span>
            <Link className="menubar__btn menubar__registerBtn" to="/register">
              {t("common:register")}
            </Link>
          </div>
          <div className="menubar__block menubar__block--loggedIn">
            <Link className="menubar__btn menubar__logoutBtn" to="/logout">
              {t("common:logout")}
            </Link>
            <Link className="menubar__btn menubar__logoutBtn" to="/profile">
              {SID}
            </Link>
          </div>
          <div
            className={`menubar__lang menubar__btn ${menuState}`}
            onClick={() => setMenuState(menuState === "" ? "active" : "")}>
            <div className="menubar__lang--close" onClick={() => setMenuState("")} />
            <div className="menubar__langBtn">
              <FontAwesome name="globe" />
              <span>{LANG_DISPLAY[i18n.language].name}</span>
            </div>
            <div className="menubar__langBar">
              {LANGUAGES.map(lang => (
                <div
                  key={lang}
                  className="menubar__langOption"
                  onClick={() => {
                    i18n.changeLanguage(lang);
                    dispatch(setLanguage(lang));
                  }}>
                  {LANG_DISPLAY[lang].name}
                </div>
              ))}
            </div>
          </div>
        </div>
        <MobileNav location={location} />
      </nav>

      <MobileMenu>
        <div>
          {LANGUAGES.map(lang => (
            <div
              key={lang}
              className={`mobileMenu__langOption ${i18n.language === lang ? "active" : ""}`}
              onClick={() => {
                i18n.changeLanguage(lang);
                dispatch(setLanguage(lang));
              }}>
              {LANG_DISPLAY[lang].name}
            </div>
          ))}
        </div>
      </MobileMenu>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    profile: state.profile,
  };
}

Nav.propTypes = {
  t: PropTypes.func,
  i18n: PropTypes.object,
  dispatch: PropTypes.func,
  location: PropTypes.object,
  profile: PropTypes.object,
  showLogin: PropTypes.bool,
  mobileNavOptions: PropTypes.string,
};

export default connect(mapStateToProps)(withTranslation()(Nav));
