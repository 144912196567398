/*!(C)2019 Rayark Inc. - All Rights Reserved
 * Rayark Confidential
 *
 * NOTICE: The intellectual and technical concepts contained herein are
 * proprietary to or under control of Rayark Inc. and its affiliates.
 * The information herein may be covered by patents, patents in process,
 * and are protected by trade secret or copyright law.
 * You may not disseminate this information or reproduce this material
 * unless otherwise prior agreed by Rayark Inc. in writing.
 */

import React from "react";
import PropTypes from "prop-types";
import { hideModal } from "../../actions/modal";

class ConfirmModal extends React.Component {
  // acceptable props:
  // onOK,
  // confirm_msg,
  // additional_content,
  // action_button_text,
  // t,
  // dispatch

  handleOK() {
    this.props.onOK();
    this.handleClose();
  }

  handleClose() {
    const { dispatch } = this.props;
    let box = document.getElementById("admin__leftNavPanel");
    box.classList.remove("modalShowing");
    dispatch(hideModal());
  }

  render() {
    const { t, additional_content, confirm_msg, action_button_text } = this.props;
    let box = document.getElementById("admin__leftNavPanel");
    box.classList.add("modalShowing");

    return (
      <div className="resultBox">
        <div className="resultBoxBack">
          <div className="resultContent result_Unfo">
            {additional_content}
            <div className="resultMSG">{t(confirm_msg)}</div>
            <div className="action">
              <div className="button delUser" onClick={this.handleOK.bind(this)}>
                {t(action_button_text)}
              </div>
              <div className="button subBtn marginBtn" onClick={this.handleClose.bind(this)}>
                {t("form:cancel")}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ConfirmModal.propTypes = {
  t: PropTypes.func,
  dispatch: PropTypes.func,
  additional_content: PropTypes.node,
  confirm_msg: PropTypes.string,
  action_button_text: PropTypes.string,
  onOK: PropTypes.func,
};

export default ConfirmModal;
