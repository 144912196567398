/*!(C)2019 Rayark Inc. - All Rights Reserved
 * Rayark Confidential
 *
 * NOTICE: The intellectual and technical concepts contained herein are
 * proprietary to or under control of Rayark Inc. and its affiliates.
 * The information herein may be covered by patents, patents in process,
 * and are protected by trade secret or copyright law.
 * You may not disseminate this information or reproduce this material
 * unless otherwise prior agreed by Rayark Inc. in writing.
 */

import {
  USER_LOGIN,
  USER_LOGOUT,
  CHECK_LOGIN_STATUS,
  RECEIVE_LOGIN_STATUS,
  CHECK_LOGIN_WITH_CALLBACK,
  LOAD_ACCESS_TOKEN,
} from "../actions/user";

const init_state = {
  loggedIn: null, // seems like not used
};

export default function userReducer(state = init_state, action) {
  switch (action.type) {
    case USER_LOGIN:
      return {
        ...state,
        loggedIn: true,
      };
    case USER_LOGOUT:
      return {
        ...state,
        loggedIn: false,
      };
    case RECEIVE_LOGIN_STATUS:
      return {
        ...state,
        loggedIn: action.loggedIn,
      };
    case CHECK_LOGIN_STATUS:
    case CHECK_LOGIN_WITH_CALLBACK:
    case LOAD_ACCESS_TOKEN:
    default:
      return state;
  }
}
