/*!(C)2019 Rayark Inc. - All Rights Reserved
 * Rayark Confidential
 *
 * NOTICE: The intellectual and technical concepts contained herein are
 * proprietary to or under control of Rayark Inc. and its affiliates.
 * The information herein may be covered by patents, patents in process,
 * and are protected by trade secret or copyright law.
 * You may not disseminate this information or reproduce this material
 * unless otherwise prior agreed by Rayark Inc. in writing.
 */

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { push } from "react-router-redux";
import RedeemView from "./RedeemView";
import { LeftNavPanel } from "../../components";
import * as redeemActions from "../../actions/redeem";
import { PAGES, SEGMENT_KEY } from "../../model/constants";
import { checkSegmentLangAndRedirect } from "../../model/helper";

import icon_redeem_redeem from "../../assets/images/icon_redeem_redeem.png";
import icon_redeem_redeem_on from "../../assets/images/icon_redeem_redeem_on.png";
import icon_redeem_history from "../../assets/images/icon_redeem_history.svg";
import icon_redeem_history_on from "../../assets/images/icon_redeem_history_on.svg";

import "./redeem.scss";

function RedeemSegment(props) {
  const { t, i18n, dispatch, app, redeem } = props;

  useEffect(() => {
    checkSegmentLangAndRedirect(SEGMENT_KEY.REDEEM, i18n.language, () =>
      dispatch(push("/profile"))
    );
  }, [dispatch, i18n]);

  const switchToPage = target => {
    dispatch(redeemActions.updateRedeemPage(target));
  };

  const getNavs = () => {
    const NAVS = [
      {
        key: PAGES.REDEEM_REDEEM,
        name: "Redeem",
        icon: {
          on: icon_redeem_redeem_on,
          off: icon_redeem_redeem,
          style: { marginBottom: "3px" },
        },
        onClick: () => switchToPage(PAGES.REDEEM_REDEEM),
      },
      {
        key: PAGES.REDEEM_HISTORY,
        name: "History",
        icon: {
          on: icon_redeem_history_on,
          off: icon_redeem_history,
        },
        onClick: () => switchToPage(PAGES.REDEEM_HISTORY),
      },
    ];

    return NAVS;
  };

  return (
    <div className="redeemSegment">
      {!!app.segment && !!app.page ? (
        <LeftNavPanel
          navs={getNavs()}
          active_key={app.page}
          show_badge={false}
          small_icon={true}
          show_icon={true}>
          {redeem ? (
            <RedeemView t={t} i18n={i18n} dispatch={dispatch} page={app.page} redeem={redeem} />
          ) : null}
        </LeftNavPanel>
      ) : null}
    </div>
  );
}

RedeemSegment.propTypes = {
  t: PropTypes.func,
  i18n: PropTypes.object,
  dispatch: PropTypes.func,
  app: PropTypes.object,
  redeem: PropTypes.object,
};

export default RedeemSegment;
