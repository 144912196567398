/*!(C)2019 Rayark Inc. - All Rights Reserved
 * Rayark Confidential
 *
 * NOTICE: The intellectual and technical concepts contained herein are
 * proprietary to or under control of Rayark Inc. and its affiliates.
 * The information herein may be covered by patents, patents in process,
 * and are protected by trade secret or copyright law.
 * You may not disseminate this information or reproduce this material
 * unless otherwise prior agreed by Rayark Inc. in writing.
 */

import {
  REQUEST_PROFILE,
  RECEIVE_PROFILE,
  REQUEST_DELETE_ACCOUNT,
  RECEIVE_DELETE_ACCOUNT_SUCCESS,
  RECEIVE_DELETE_ACCOUNT_FAILURE,
} from "../actions/profile";

import { USER_LOGOUT } from "../actions/user";

const deleteAccountInitial = {
  loading: false,
  success: false,
}

const deleteAccountLoading = {
  loading: true,
  success: false,
}

const deleteAccountSuccess = {
  loading: false,
  success: true,
}

const initial_state = {
  // profile
  // oid
  // sid
  // allow_modify_sid
  // credentials
  // games
  info: {
    birthday_day: null,
    birthday_month: null,
    birthday_year: null,
    email: null,
    phone: null,
  },
  deleteAccount: deleteAccountInitial,
};

export default function userReducer(state = initial_state, action) {
  switch (action.type) {
    case RECEIVE_PROFILE:
      return {
        ...state,
        ...action.profile,
      };
    // case RECEIVE_PROFILE:
    //   return {
    //     ...state,
    //     info: {
    //       ...state.info,
    //       ...action.info
    //     }
    //   }
    case REQUEST_DELETE_ACCOUNT:
      return {
        ...state,
        deleteAccount: deleteAccountLoading,
      };
    case RECEIVE_DELETE_ACCOUNT_SUCCESS:
      return {
        ...state,
        deleteAccount: deleteAccountSuccess,
      };
    case RECEIVE_DELETE_ACCOUNT_FAILURE:
      return {
        ...state,
        deleteAccount: deleteAccountInitial,
      };
    case USER_LOGOUT:
      return null;
    case REQUEST_PROFILE:
    default:
      return state;
  }
}
