/*!(C)2019 Rayark Inc. - All Rights Reserved
 * Rayark Confidential
 *
 * NOTICE: The intellectual and technical concepts contained herein are
 * proprietary to or under control of Rayark Inc. and its affiliates.
 * The information herein may be covered by patents, patents in process,
 * and are protected by trade secret or copyright law.
 * You may not disseminate this information or reproduce this material
 * unless otherwise prior agreed by Rayark Inc. in writing.
 */

import React from "react";
import PropTypes from "prop-types";
import { UserSID, Avatar } from "../../components";
import ConfirmModal from "./ConfirmModal";

function ConfirmPlayerModal({ t, dispatch, player, confirm_msg, action_button_text, onOK }) {
  const additional_content = (
    <div className="sid_result">
      <Avatar avatarURL={player.avatar} />
      <UserSID SID={player.sid} />
    </div>
  );

  const confirm_modal_props = {
    onOK,
    confirm_msg,
    additional_content,
    action_button_text,
    t,
    dispatch,
  };

  return <ConfirmModal {...confirm_modal_props} />;
}

ConfirmPlayerModal.propTypes = {
  t: PropTypes.func,
  dispatch: PropTypes.func,
  player: PropTypes.object,
  confirm_msg: PropTypes.string,
  action_button_text: PropTypes.string,
  onOK: PropTypes.func,
};

export default ConfirmPlayerModal;
