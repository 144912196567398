/*!(C)2019 Rayark Inc. - All Rights Reserved
 * Rayark Confidential
 *
 * NOTICE: The intellectual and technical concepts contained herein are
 * proprietary to or under control of Rayark Inc. and its affiliates.
 * The information herein may be covered by patents, patents in process,
 * and are protected by trade secret or copyright law.
 * You may not disseminate this information or reproduce this material
 * unless otherwise prior agreed by Rayark Inc. in writing.
 */

import moment from "moment";
import { SEGMENTS, USER_CREDENTIAL_TYPE } from "../model/constants";

/****** Data processing utilities ******/

export function isEmptyObj(obj) {
  return Object.getOwnPropertyNames(obj).length === 0;
}

export function paddingZeroPrefix(str, length) {
  const str_reverse = str.split("").reverse().join("");
  let ret = Array.apply(0, { length: length });
  for (let i = length - 1; i >= 0; i--) {
    ret[i] = i >= str.length ? 0 : str_reverse[i];
  }

  return ret.reverse().join("");
}

export function getByKeyPathWithFallbackValue(source_obj, key_path, default_value) {
  const fail_token = "__FAIL_TOKEN_getByKeyPathWithFallbackValue_";

  const destination = key_path.reduce((child_obj, key) => {
    if (child_obj === fail_token) {
      return fail_token;
    } else {
      const target = child_obj[key];
      return target !== undefined && target !== null ? target : fail_token;
    }
  }, source_obj || {});
  return destination === fail_token ? default_value : destination;
}

export function listToObj(list) {
  return list.reduce((l, r) => ({ ...l, ...r }), {});
}

export function objToList(obj) {
  return Object.keys(obj).map(key => obj[key]);
}

export function getDateDisplay(dt) {
  return moment(dt).format("YYYY/MM/DD");
}

export function getTimeDisplay(dt) {
  return moment(dt).format("HH:mm");
}

export function getDateTimeDisplay(dt) {
  return dt === "" ? "" : moment(dt).format("YYYY/MM/DD HH:mm");
}

export function getDateTimeProtocolString(dt) {
  return Math.round(moment(dt).seconds(0).valueOf() / 1000);
}

export function getDateTimeFromProtocol(dt, is_utc) {
  return is_utc ? moment.utc(dt * 1000).seconds(0) : moment(dt * 1000).seconds(0);
}

/****** App ralated utilities ******/

export function getSegmentDataModel(segment_key) {
  return SEGMENTS.find(seg => seg.key === segment_key);
}

export function checkSegmentLangAndRedirect(segment_key, lang, redirect_func) {
  const segment = getSegmentDataModel(segment_key);
  const hide_for_languages = segment.hide_for_languages || [];
  if (hide_for_languages.includes(lang)) {
    redirect_func();
  }
}
