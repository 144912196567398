/*!(C)2021 Rayark Inc. - All Rights Reserved
 * Rayark Confidential
 *
 * NOTICE: The intellectual and technical concepts contained herein are
 * proprietary to or under control of Rayark Inc. and its affiliates.
 * The information herein may be covered by patents, patents in process,
 * and are protected by trade secret or copyright law.
 * You may not disseminate this information or reproduce this material
 * unless otherwise prior agreed by Rayark Inc. in writing.
 */

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { showModal } from "../../actions/modal";
import { Dialog, DialogTitle, DialogSubtitle, DialogContent, DialogFooter } from "../../layouts";

const SettingSID = ({ t, dispatch, profile }) => {
  const [newSID, updateNewSID] = useState("");

  const validateSID = () => {
    // Since the pattern is static, we use regular expression literal.
    // If it is dynamic, using RegExp to new a pattern is suggested.
    // For more info, please see https://developer.mozilla.org/zh-TW/docs/Web/JavaScript/Guide/Regular_Expressions
    const pattern = /^[A-Za-z][0-9A-Z_a-z]{2,11}$/;
    const blackList = ["guest", "rayarkplayer"];

    return (
      pattern.test(newSID) && blackList.filter(name => newSID.toLowerCase() === name).length === 0
    );
  };

  const handleUpdateSID = () => {
    const oldSID = profile.sid.split("#")[0];

    if (newSID === oldSID) {
      updateNewSID("");
      dispatch(showModal("UPDATE_SID", { errMSG: t("profile:editDuplicate") }));
      return;
    }

    if (!validateSID(newSID)) {
      updateNewSID("");
      dispatch(showModal("UPDATE_SID", { errMSG: t("profile:editError") }));
      return;
    }

    dispatch(showModal("UPDATE_SID", { sid: newSID }));
  };

  return (
    <Dialog className="setting__settingBox setting_subPage">
      <DialogTitle mobileTitle={t("profile:setting_SID_title")} />
      <DialogSubtitle pcSubTitle={t("profile:setting_SID_title")} />
      <DialogContent className="setting__settingBlock">
        <div className="setting__item">
          <div className="setting__title">{t("profile:enter_SID_title")}</div>
          <input
            type="text"
            className="setting__input"
            onChange={e => updateNewSID(e.target.value)}
          />
          <div className="setting__updateSidBtn pc" onClick={handleUpdateSID}>
            {t("form:ok")}
          </div>
          <span className="hintText">{t("profile:once_sid_hint")}</span>
        </div>
        <div className="sidRule">
          {t("profile:naming_rule")
            .split("\n")
            .map((str, index) => (
              <div key={`rule_${index}`}>{str}</div>
            ))}
        </div>
      </DialogContent>

      <DialogFooter>
        <Link to="/setting" className="button backBtn pc">
          {t("form:cancel")}
        </Link>
        <div className="mobile action">
          <div className="button" onClick={handleUpdateSID}>
            {t("form:ok")}
          </div>
          <Link to="/setting" className="button backBtn">
            {t("form:cancel")}
          </Link>
        </div>
      </DialogFooter>
    </Dialog>
  );
};

SettingSID.propTypes = {
  t: PropTypes.func,
  dispatch: PropTypes.func,
  profile: PropTypes.object,
};

export default SettingSID;
