/*!(C)2019 Rayark Inc. - All Rights Reserved
 * Rayark Confidential
 *
 * NOTICE: The intellectual and technical concepts contained herein are
 * proprietary to or under control of Rayark Inc. and its affiliates.
 * The information herein may be covered by patents, patents in process,
 * and are protected by trade secret or copyright law.
 * You may not disseminate this information or reproduce this material
 * unless otherwise prior agreed by Rayark Inc. in writing.
 */

import config from "../variables";
import {
  checkStatus,
  parseJSON,
  postDataWithHeaderToken,
  getDataWithHeaderToken,
} from "../model/network";
import { receiveError, clearError } from "./error";
import { PAGES } from "../model/constants";
import * as appAction from "./app";

export const RECEIVE_FRIEND_PAGE_DATA = "RECEIVE_FRIEND_PAGE_DATA";
export const SEARCH_USER = "SEARCH_USER";
export const FOLLOW = "FOLLOW";
export const UNFOLLOW = "UNFOLLOW";

const AVAILABLE_PAGES = [
  {
    key: PAGES.FOLLOWING,
    url: `${config.serverUrl}/player/following`,
  },
  {
    key: PAGES.FOLLOWERS,
    url: `${config.serverUrl}/player/followers`,
  },
];

const PAGE_DATA = AVAILABLE_PAGES.map(page => {
  let o = {};
  o[page.key] = page;
  return o;
}).reduce((l, r) => ({ ...l, ...r }), {});

function receivePageData(page, data) {
  return {
    type: RECEIVE_FRIEND_PAGE_DATA,
    page: page,
    data: data,
  };
}

export const fetchPagesData = pages => dispatch => {
  pages
    .map(page => {
      const page_found = AVAILABLE_PAGES.find(p => p.key === page);
      return page_found ? page_found : null;
    })
    .filter(page => !!page)
    .map(page => {
      getDataWithHeaderToken(page.url)
        .then(checkStatus)
        .then(parseJSON)
        .then(json => dispatch(receivePageData(page.key, json)))
        .catch(error => {
          console.error(error);
          dispatch(receiveError("modal", error));
        });
    });
};

export const fetchFriendSegmentData = () => dispatch => {
  dispatch(fetchPagesData([PAGES.FOLLOWING, PAGES.FOLLOWERS]));
};

export const updateFriendPage = page => dispatch => {
  const page_found = AVAILABLE_PAGES.filter(p => p.key === page).length
    ? AVAILABLE_PAGES.filter(p => p.key === page)[0]
    : null;

  if (page_found) {
    dispatch(appAction.updateCurrentPage(page));
    if (page_found.url) {
      getDataWithHeaderToken(page_found.url)
        .then(checkStatus)
        .then(parseJSON)
        .then(json => dispatch(receivePageData(page, json)))
        .catch(error => {
          console.error(error);
          dispatch(receiveError("modal", error));
        });
    }
  } else {
    dispatch(receiveError("FRIENDS_ERR", { errMSG: "PAGE_NOT_FOUND" }));
  }
};

export const searchUser = sid => dispatch => {
  dispatch(clearError());

  const checkSID = sid.toLowerCase().split("#");
  if (checkSID[0] === "guest") {
    dispatch(receiveError("FRIENDS_ERR", { errMSG: "SEARCH_GUEST" }));
  }

  const url = `${config.serverUrl}/search/players?sid=${encodeURIComponent(sid)}`;
  getDataWithHeaderToken(url)
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      dispatch({ type: SEARCH_USER, results: json.results });
    })
    .catch(error => {
      dispatch(receiveError("FRIENDS_ERR", error));
    });
};

export const follow = player => dispatch => {
  dispatch(clearError());

  const data = {
    action: "follow",
    sid: player.sid,
  };

  postDataWithHeaderToken(PAGE_DATA[PAGES.FOLLOWING].url, data)
    .then(checkStatus)
    .then(parseJSON)
    .then(_ => {
      dispatch({ type: FOLLOW, player: player });
      dispatch(fetchFriendSegmentData());
    })
    .catch(error => dispatch(receiveError("FRIENDS_ERR", error)));
};

export const unfollow = player => dispatch => {
  dispatch(clearError());

  const data = {
    action: "unfollow",
    sid: player.sid,
  };

  postDataWithHeaderToken(PAGE_DATA[PAGES.FOLLOWING].url, data)
    .then(checkStatus)
    .then(parseJSON)
    .then(_ => {
      dispatch({ type: UNFOLLOW, player: player });
      dispatch(fetchFriendSegmentData());
    })
    .catch(error => {
      dispatch(receiveError("FRIENDS_ERR", error));
    });
};
