/*!(C)2019 Rayark Inc. - All Rights Reserved
 * Rayark Confidential
 *
 * NOTICE: The intellectual and technical concepts contained herein are
 * proprietary to or under control of Rayark Inc. and its affiliates.
 * The information herein may be covered by patents, patents in process,
 * and are protected by trade secret or copyright law.
 * You may not disseminate this information or reproduce this material
 * unless otherwise prior agreed by Rayark Inc. in writing.
 */

module.exports = {
  siteUrl: "https://wk6hed-beta.rayark-pass.net",
  serverUrl: "https://api-bifrost-beta.rayark-pass.net",
  rayark_appkey: "W0E8/FqmdZIOdQNwIa+4Wg",
  reCaptcha_sitekey: "6LeLdhoUAAAAAEmAHeviCoECWaX4pmyiGmHZtTlv",
  fb_appid: "1779915348966090",
  HRPage: "https://www.rayark.com/event/jobs/index.html",
  rayarkFB: "https://www.facebook.com/rayark.inc",
  rayarkYT: "https://www.youtube.com/RayarkInc",
  termPage: {
    zh: "https://www.terms.rayark.com/",
    en: "https://www.terms.rayark.com/terms-en",
    ja: "https://www.terms.rayark.com/terms-jp",
    ko: "https://www.terms.rayark.com/terms-kr",
  },
  debug: {
    disableUpdateSIDCheck: false,
  },
};
