/*!(C)2019 Rayark Inc. - All Rights Reserved
 * Rayark Confidential
 *
 * NOTICE: The intellectual and technical concepts contained herein are
 * proprietary to or under control of Rayark Inc. and its affiliates.
 * The information herein may be covered by patents, patents in process,
 * and are protected by trade secret or copyright law.
 * You may not disseminate this information or reproduce this material
 * unless otherwise prior agreed by Rayark Inc. in writing.
 */

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { hideModal } from "../../actions/modal";

// The modal dialog has similar implementation to
// http://stackoverflow.com/a/35641680

import AlertInnerModal from "./AlertInnerModal";
import CloneDoneModal from "./CloneDoneModal";
import ConfirmPlayerModal from "./ConfirmPlayerModal";
import ConfirmUnfollowInnerModal from "./ConfirmUnfollowInnerModal";
import ErrorInnerModal from "./ErrorInnerModal";
import SearchResultInnerModal from "./SearchResultInnerModal";
import SIDInnerModal from "./SIDInnerModal";
import SimpleMessageModal from "./SimpleMessageModal";

const MODAL_COMPONENTS = {
  SIMPLE_MSG: SimpleMessageModal,
  SEARCH_RESULT: SearchResultInnerModal,
  FRIENDS_ERR: SearchResultInnerModal,
  CONFIRM_UNFOLLOW: ConfirmUnfollowInnerModal,
  CLONE_DONE: CloneDoneModal,
  ERROR: ErrorInnerModal,
  ALERT: AlertInnerModal,
  UPDATE_SID: SIDInnerModal,
  CONFIRM_PLAYER: ConfirmPlayerModal,
};

class Modal extends React.Component {
  handleClose() {
    const { dispatch } = this.props;
    dispatch(hideModal());
  }

  render() {
    const { t, dispatch, modal } = this.props;

    if (!modal.type) {
      return null;
    }

    const InnerModal = MODAL_COMPONENTS[modal.type];
    let backdrop = <div className="backdrop" />;
    if (modal.type === "CLONE_DONE") {
      backdrop = null;
    }

    const props_modal = { ...modal.props, t, dispatch };
    return (
      <div className="modal">
        {backdrop}
        <InnerModal {...props_modal} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    modal: state.modal,
  };
}

Modal.propTypes = {
  t: PropTypes.func,
  dispatch: PropTypes.func,
  modal: PropTypes.object,
};

export default connect(mapStateToProps)(withTranslation()(Modal));
