import React from "react";
import PropTypes from "prop-types";

function Loading({ text = "" }) {
  return (
    <div className="background background--loading">
      <div className="loading-ring">
        <div className="loading-line"></div>
        <div className="loading-line"></div>
        <div className="loading-line"></div>
        <div className="loading-line"></div>
        <div className="loading-text">{text || "Page Loading..."}</div>
      </div>
    </div>
  );
}

Loading.propTypes = {
  text: PropTypes.string,
};

export default Loading;
