/*!(C)2019 Rayark Inc. - All Rights Reserved
 * Rayark Confidential
 *
 * NOTICE: The intellectual and technical concepts contained herein are
 * proprietary to or under control of Rayark Inc. and its affiliates.
 * The information herein may be covered by patents, patents in process,
 * and are protected by trade secret or copyright law.
 * You may not disseminate this information or reproduce this material
 * unless otherwise prior agreed by Rayark Inc. in writing.
 */

// import fromCodePoint from 'core-js/features/string/from-code-point';
import base64url from "base64url";
import { push } from "react-router-redux";
import config from "../variables";
import { fetchProfile } from "./profile";
import {
  checkStatus,
  parseJSON,
  postDataWithHeaderToken,
  postFormTo,
  postData,
  toQueryString,
} from "../model/network";
import { receiveError, clearError } from "./error";
import { _checkAccessTokenInSession } from "./auth";
import { hideModal } from "./modal";

export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGOUT = "USER_LOGOUT";
export const CHECK_LOGIN_STATUS = "CHECK_LOGIN_STATUS";
export const RECEIVE_LOGIN_STATUS = "RECEIVE_LOGIN_STATUS";
export const USER_TWITTER_AUTHENTICATED = "USER_TWITTER_AUTHENTICATED";
export const CHECK_LOGIN_WITH_CALLBACK = "CHECK_LOGIN_WITH_CALLBACK";
export const LOAD_ACCESS_TOKEN = "LOAD_ACCESS_TOKEN";
export const REQUEST_SET_LANGUAGE = "REQUEST_SET_LANGUAGE";

const sha256 = require("node-forge/lib/sha256.js");

const _getCodeChallengeVerifier = () => {
  const crypto = window.crypto;
  if (typeof crypto === "undefined") {
    console.error(`NO_WEBCRYPTO`);
    throw new Error("NO_WEBCRYPTO");
  } else {
    let cv_arr = new Uint8Array(32);
    crypto.getRandomValues(cv_arr);
    // const cv_bs = fromCodePoint(...cv_arr) // get binary string
    const cv_base64_trimtail = base64url.encode(cv_arr.buffer);

    try {
      let digestor = sha256.create();
      digestor.update(cv_base64_trimtail); // default encoding: utf8
      const challenge = base64url.encode(digestor.digest().data, "binary");
      return { challenge: challenge, verifier: cv_base64_trimtail };
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
};

function receiveLoginStatus(loggedIn) {
  return {
    type: RECEIVE_LOGIN_STATUS,
    loggedIn: loggedIn,
  };
}

export const requestEmailAuth = ({ email, password, gRecaptchaResponse }) => dispatch => {
  // get `ref` from sessionStorage
  const ref = window.sessionStorage.getItem("ref");
  // get csrf token
  fetch(`${config.serverUrl}/csrf_token`, { credentials: "include" })
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      const csrf_token = json.csrf_token;
      const custom_header = {
        "X-CSRF-Token": csrf_token,
      };
      const custom_options = {
        credentials: "include",
      };
      const data = Object.assign(
        {
          account: email,
          secret: password,
          "g-recaptcha-response": gRecaptchaResponse,
        },
        ref ? { ref } : { ref: "" }
      );

      return postData(
        `${config.serverUrl}/service/email/login`,
        data,
        custom_header,
        custom_options
      );
    })
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      dispatch({ type: USER_LOGIN });
      window.localStorage.setItem("accessToken", json.access_token);

      if (json.redirect_uri) {
        // OAuth mode
        window.location.href = `${config.siteUrl}/auth_continue${toQueryString(json)}`;
      } else {
        // normal mode
        dispatch(fetchProfile());
        dispatch(clearError());
        dispatch(push("/my-games"));
      }
    })
    .catch(error => {
      dispatch(receiveError("login", error));
    });
};

export const requestFacebookAuth = () => _dispatch => {
  // get `ref` from sessionStorage
  const ref = window.sessionStorage.getItem("ref");
  // post csrf token, get the token.
  fetch(`${config.serverUrl}/csrf_token`, { credentials: "include" })
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      // gen rp_challenge
      const csrf_token = json.csrf_token;
      const { challenge, verifier } = _getCodeChallengeVerifier();
      window.sessionStorage.setItem("rp_cv", verifier);
      postFormTo(`${config.serverUrl}/service/facebook/login`, {
        csrf_token: csrf_token,
        ref: ref,
        code_challenge: challenge,
        code_challenge_method: "S256",
      });
    })
    .catch(e => {
      console.error(e);
    });
};

export const requestTwitterAuth = () => _dispatch => {
  // get `ref` from sessionStorage
  const ref = window.sessionStorage.getItem("ref");
  // post csrf token, get the token.
  fetch(`${config.serverUrl}/csrf_token`, { credentials: "include" })
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      // gen rp_challenge
      const csrf_token = json.csrf_token;
      const { challenge, verifier } = _getCodeChallengeVerifier();
      window.sessionStorage.setItem("rp_cv", verifier);
      postFormTo(`${config.serverUrl}/service/twitter/login`, {
        csrf_token: csrf_token,
        ref: ref,
        code_challenge: challenge,
        code_challenge_method: "S256",
      });
    })
    .catch(e => {
      console.error(e);
    });
};

export const requestGoogleAuth = () => _dispatch => {
  // get `ref` from sessionStorage
  const ref = window.sessionStorage.getItem("ref");
  // post csrf token, get the token.
  fetch(`${config.serverUrl}/csrf_token`, { credentials: "include" })
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      // gen rp_challenge
      const csrf_token = json.csrf_token;
      const { challenge, verifier } = _getCodeChallengeVerifier();
      window.sessionStorage.setItem("rp_cv", verifier);
      postFormTo(`${config.serverUrl}/service/google/login`, {
        csrf_token: csrf_token,
        ref: ref,
        code_challenge: challenge,
        code_challenge_method: "S256",
      });
    })
    .catch(e => {
      console.error(e);
    });
};

export const requestAppleAuth = () => _dispatch => {
  // get `ref` from sessionStorage
  const ref = window.sessionStorage.getItem("ref");
  // post csrf token, get the token.
  fetch(`${config.serverUrl}/csrf_token`, { credentials: "include" })
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      // gen rp_challenge
      const csrf_token = json.csrf_token;
      const { challenge, verifier } = _getCodeChallengeVerifier();
      window.sessionStorage.setItem("rp_cv", verifier);
      postFormTo(`${config.serverUrl}/service/apple/login`, {
        csrf_token: csrf_token,
        ref: ref,
        code_challenge: challenge,
        code_challenge_method: "S256",
      });
    })
    .catch(e => {
      console.error(e);
    });
};

// trigger fetch profile, but doesn't redirect user agent
export const loadAccessToken = () => dispatch => {
  dispatch({ type: LOAD_ACCESS_TOKEN });

  const accessToken = window.localStorage.getItem("accessToken");
  _checkAccessTokenInSession(accessToken)
    .then(() => {
      dispatch({ type: USER_LOGIN });
      dispatch(fetchProfile());
    })
    .catch(() => {
      dispatch({ type: USER_LOGOUT });
    });
};

// redirect UA to /my-games (was /profile) when success
export const checkLoginStatus =
  ({ succeedPath, failPath, fetchDataFuncs = [] }) =>
  dispatch => {
    dispatch({ type: CHECK_LOGIN_STATUS });
    const accessToken = window.localStorage.getItem("accessToken");
    if (accessToken) {
      _checkAccessTokenInSession(accessToken)
        .then(_ => {
          dispatch(clearError());
          dispatch(receiveLoginStatus(true));
          dispatch(push(succeedPath));
          fetchDataFuncs.forEach(func => func());
        })
        .catch(e => {
          console.error(e);
          dispatch(receiveLoginStatus(false));
          dispatch(push(failPath));
        });
    } else {
      dispatch(push(failPath));
    }
  };

// redirect UA to /login
export const logout = (callback_url, callback) => dispatch => {
  dispatch(hideModal());

  fetch(`${config.serverUrl}/csrf_token`, { credentials: "include" })
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      const csrf_token = json.csrf_token;
      const custom_header = {
        "X-CSRF-Token": csrf_token,
      };
      const custom_options = {
        credentials: "include",
      };
      postDataWithHeaderToken(`${config.serverUrl}/service/logout`, {}, custom_header, custom_options)
        .then(() => {
          // logout success
          dispatch({ type: USER_LOGOUT });
          window.localStorage.removeItem("accessToken");
          window.sessionStorage.removeItem("loginCallbackUrl");
          window.sessionStorage.removeItem("hasSetLang");
          dispatch(push(callback_url ? callback_url : "/login"));
          if (typeof callback === "function") callback();
        })
        .catch(error => {
          dispatch(receiveError("logout", error));
        });
    });
};

/* used by other application sites in rayark-pass domain */
// default failCB is redirecting to /login then back whenever successful login
// TODO: to be deprecated as RPAuth already supports the function.
export const checkLoginWithFailCB = caller_cb_not_login => dispatch => {
  dispatch({ type: CHECK_LOGIN_WITH_CALLBACK });

  const accessToken = window.localStorage.getItem("accessToken");
  const loginCallbackUrl = window.location.pathname;

  const cb_not_login = () => {
    if (typeof caller_cb_not_login === "function") caller_cb_not_login();
    else dispatch(loginWithCallbackURL(loginCallbackUrl));
  };

  if (accessToken) {
    _checkAccessTokenInSession(accessToken)
      .then(() => {
        // auth successfully
        dispatch(receiveLoginStatus(true));
      })
      .catch(() => {
        // auth failed: not login yet
        cb_not_login();
      });
  }
};

// TODO: to be deprecated as RPAuth already supports the function.
export const loginWithCallbackURL = loginCallbackUrl => dispatch => {
  sessionStorage.setItem("loginCallbackUrl", loginCallbackUrl);
  dispatch(push("/login"));
};

export const setLanguage = lang => dispatch => {
  dispatch({ type: REQUEST_SET_LANGUAGE });

  if (window.localStorage.getItem("accessToken")) {
    postDataWithHeaderToken(`${config.serverUrl}/player/language`, { lang: lang })
      .then(checkStatus)
      .then(parseJSON)
      .then(_ => null)
      .catch(error => {
        console.error(error);
      });
  }
};
