import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {
  requestFacebookAuth,
  requestTwitterAuth,
  requestGoogleAuth,
  requestAppleAuth,
} from "../actions/user";
import { LONG_LANGUAGES, LANG_DISPLAY } from "../model/constants";
import googlePlayIcon from "../assets/images/icon_google_play.png";
import FontAwesome from "react-fontawesome";

export function FacebookAuth({ t, dispatch }) {
  return (
    <div className="FBBtn" onClick={() => dispatch(requestFacebookAuth())}>
      <FontAwesome name="facebook" />
      <span>{t("form:login_with_facebook")}</span>
    </div>
  );
}

FacebookAuth.propTypes = {
  t: PropTypes.func,
  dispatch: PropTypes.func,
};

export function TwitterAuth({ t, dispatch }) {
  return (
    <div className="TWBtn" onClick={() => dispatch(requestTwitterAuth())}>
      <FontAwesome name="twitter" />
      <span>{t("form:login_with_twitter")}</span>
    </div>
  );
}

TwitterAuth.propTypes = {
  t: PropTypes.func,
  dispatch: PropTypes.func,
};

// Google login is the same with Google Play login
// To fit the UI with app client, use Google Play icon
export function GoogleAuth({ t, dispatch }) {
  return (
    <div className="GoogleBtn" onClick={() => dispatch(requestGoogleAuth())}>
      <img src={googlePlayIcon} alt="google-play" />
      <span>{t("form:login_with_google")}</span>
    </div>
  );
}

GoogleAuth.propTypes = {
  t: PropTypes.func,
  dispatch: PropTypes.func,
};

export function AppleAuth({ t, dispatch }) {
  return (
    <div className="AppleBtn" onClick={() => dispatch(requestAppleAuth())}>
      <FontAwesome name="apple" />
      <span>{t("form:login_with_apple")}</span>
    </div>
  );
}

AppleAuth.propTypes = {
  t: PropTypes.func,
  dispatch: PropTypes.func,
};

function ThirdPartyLogin({ t, i18n, dispatch, hideAppleAndGoogle }) {
  const makeGroups = arr => {
    return arr.length <= 2 ? [arr] : [[arr[0], arr[1]]].concat(makeGroups(arr.slice(2)));
  };

  const authenticators = [
    {
      component: <FacebookAuth t={t} dispatch={dispatch} />,
      is_enable: true,
    },
    {
      component: <TwitterAuth t={t} dispatch={dispatch} />,
      is_enable: true,
    },
    {
      component: <GoogleAuth t={t} dispatch={dispatch} />,
      is_enable: !hideAppleAndGoogle,
    },
    {
      component: <AppleAuth t={t} dispatch={dispatch} />,
      is_enable: !hideAppleAndGoogle,
    },
  ].filter(authenticator => authenticator.is_enable);

  const login_btn_classes = LANG_DISPLAY[i18n.language].loginBtnStyles
    .map(style => `login-button--${style}`)
    .join(" ");

  return (
    <div className="login-button-group">
      {authenticators.length % 2 || LONG_LANGUAGES.includes(i18n.language)
        ? authenticators.map((authenticator, index) => (
            <div key={index} className="login-button-row">
              <div className={`login-button login-button--full ${login_btn_classes}`}>
                {authenticator.component}
              </div>
            </div>
          ))
        : makeGroups(authenticators).map((group, index) => (
            <div key={index} className="login-button-row">
              <div className={`login-button ${login_btn_classes}`}>{group[0].component}</div>
              <div className={`login-button ${login_btn_classes}`}>
                {group[1] ? group[1].component : ""}
              </div>
            </div>
          ))}
    </div>
  );
}

ThirdPartyLogin.propTypes = {
  t: PropTypes.func,
  i18n: PropTypes.object,
  dispatch: PropTypes.func,
  hideAppleAndGoogle: PropTypes.bool,
};

export default connect()(withTranslation()(ThirdPartyLogin));
