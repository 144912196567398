/*!(C)2019 Rayark Inc. - All Rights Reserved
 * Rayark Confidential
 *
 * NOTICE: The intellectual and technical concepts contained herein are
 * proprietary to or under control of Rayark Inc. and its affiliates.
 * The information herein may be covered by patents, patents in process,
 * and are protected by trade secret or copyright law.
 * You may not disseminate this information or reproduce this material
 * unless otherwise prior agreed by Rayark Inc. in writing.
 */

import { RECEIVE_FRIEND_PAGE_DATA, SEARCH_USER, FOLLOW, UNFOLLOW } from "../actions/friends";

import { USER_LOGOUT } from "../actions/user";

import { PAGES } from "../model/constants";

const init_state = {
  searchResult: [],
  following: [],
  followers: [],
  maxFollowing: 300,
  showList: PAGES.FOLLOWING,
};

function getCookedPageData(page, data) {
  switch (page) {
    case PAGES.FOLLOWING:
      return {
        following: data.following,
        maxFollowing: data.max_following,
      };
    case PAGES.FOLLOWERS:
      return {
        followers: data.followers,
        maxFollower: data.max_follower,
      };
    case PAGES.REDEEM_REDEEM:
    case PAGES.REDEEM_HISTORY:
      return { ...data };
    default:
      return null;
  }
}

export default function friendsReducer(state = init_state, action) {
  switch (action.type) {
    case SEARCH_USER:
      return {
        ...state,
        searchResult: action.results,
      };

    case FOLLOW: {
      const player = action.player;
      const following = [...state.following, player];
      const searchResult = state.searchResult.filter(who => who.sid !== player.sid);

      return {
        ...state,
        following: following,
        searchResult: searchResult,
      };
    }

    case UNFOLLOW: {
      const player = action.player;
      const following = state.following.filter(who => who.sid !== player.sid);
      return {
        ...state,
        following: following,
      };
    }

    case USER_LOGOUT:
      return init_state;

    case RECEIVE_FRIEND_PAGE_DATA:
      return {
        ...state,
        ...getCookedPageData(action.page, action.data),
      };

    default:
      return state;
  }
}
