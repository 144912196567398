import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import rayarkpass_logo_large from "../assets/images/rayarkpassLOGO.png";

function GeneralError({ t, message }) {
  function handleBackToEventPage(e) {
    e.preventDefault();
    window.location.href = window.sessionStorage.getItem("redirect_uri");
    window.sessionStorage.clear();
  }

  const redirect_uri = window.sessionStorage.getItem("redirect_uri");

  return (
    <div className="center">
      <div className="background"></div>
      <div className="formDecBox">
        <div className="decImg">
          <img className="logo" src={rayarkpass_logo_large} />
        </div>
        <div className="area errorInfoArea">
          <div className="pageTitle">
            <span className="pc">ERROR</span>
            <img className="mobile logo" src={rayarkpass_logo_large} />
            <span className="mobile">ERROR</span>
          </div>
          <div className="description">{message ? message : t("error:GENERAL_ERROR")}</div>
          {redirect_uri ? (
            <Link className="button backBtn errorBackBtn" onClick={handleBackToEventPage}>
              {t("common:return")}
            </Link>
          ) : (
            <Link to="/" className="button backBtn errorBackBtn">
              {t("common:back_to_index")}
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}

GeneralError.propTypes = {
  t: PropTypes.func,
  message: PropTypes.string,
};

export default GeneralError;
