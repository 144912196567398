/*!(C)2019 Rayark Inc. - All Rights Reserved
 * Rayark Confidential
 *
 * NOTICE: The intellectual and technical concepts contained herein are
 * proprietary to or under control of Rayark Inc. and its affiliates.
 * The information herein may be covered by patents, patents in process,
 * and are protected by trade secret or copyright law.
 * You may not disseminate this information or reproduce this material
 * unless otherwise prior agreed by Rayark Inc. in writing.
 */

import config from "../variables";
import {
  checkStatus,
  parseJSON,
  getDataWithHeaderToken,
  postDataWithHeaderToken,
} from "../model/network";
import { receiveError } from "./error";
import { showSimpleModal } from "./modal";
import { PAGES } from "../model/constants";
import * as appAction from "./app";

export const REQUEST_GAMES = "REQUEST_GAMES";
export const RECEIVE_GAMES = "RECEIVE_GAMES";
export const REQUEST_SERVERS_AND_CHARACTERS = "REQUEST_SERVERS_AND_CHARACTERS";
export const RECEIVE_SERVERS_AND_CHARACTERS = "RECEIVE_SERVERS_AND_CHARACTERS";
export const REQUEST_REDEEM_TRANSACTIONS = "REQUEST_REDEEM_TRANSACTIONS";
export const RECEIVE_REDEEM_TRANSACTIONS = "RECEIVE_REDEEM_TRANSACTIONS";
export const REQUEST_REDEEM_REWARDS = "REQUEST_REDEEM_REWARDS";
export const RECEIVE_REDEEM_REWARDS = "RECEIVE_REDEEM_REWARDS";
export const REQUEST_POST_REDEEM_CODE = "REQUEST_POST_REDEEM_CODE";
export const RECEIVE_POST_REDEEM_CODE = "RECEIVE_POST_REDEEM_CODE";
export const REQUEST_REDEEM_PAGE_DATA = "REQUEST_REDEEM_PAGE_DATA";
export const RECEIVE_REDEEM_PAGE_DATA = "RECEIVE_REDEEM_PAGE_DATA";

function receiveGames(games) {
  return {
    type: RECEIVE_GAMES,
    games: games,
  };
}

function receiveServersAndCharacters(data) {
  return {
    type: RECEIVE_SERVERS_AND_CHARACTERS,
    serversAndCharacters: data || {}, // would be null if no any character
  };
}

function receiveRewards(rewards) {
  return {
    type: RECEIVE_REDEEM_REWARDS,
    rewards: rewards,
  };
}

function receiveTransactions(transactions) {
  return {
    type: RECEIVE_REDEEM_TRANSACTIONS,
    transactions: transactions.sort((t1, t2) => t2.time - t1.time),
  };
}

export const getGames = () => dispatch => {
  dispatch({ type: REQUEST_GAMES });

  getDataWithHeaderToken(`${config.serverUrl}/games`)
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      dispatch(receiveGames(json.games));
    })
    .catch(error => {
      console.error(error);
      dispatch(receiveError("modal", error));
    });
};

export const getServersAndCharacters = () => dispatch => {
  dispatch({ type: REQUEST_SERVERS_AND_CHARACTERS });

  getDataWithHeaderToken(`${config.serverUrl}/player/characters`)
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      dispatch(receiveServersAndCharacters(json.characters));
    })
    .catch(error => {
      console.error(error);
      dispatch(receiveError("modal", error));
    });
};

export const getRewardsAndTransactions = () => dispatch => {
  dispatch({ type: REQUEST_REDEEM_REWARDS });
  dispatch({ type: REQUEST_REDEEM_TRANSACTIONS });

  getDataWithHeaderToken(`${config.serverUrl}/redeem/transactions`)
    .then(checkStatus)
    .then(parseJSON)
    .then(json => {
      dispatch(receiveRewards(json.rewards));
      dispatch(receiveTransactions(json.transactions));
    })
    .catch(error => {
      console.error(error);
      dispatch(receiveError("modal", error));
    });
};

export const postRedeem = ({ code, game_id, server_id, onSuccess }) => dispatch => {
  dispatch({ type: REQUEST_POST_REDEEM_CODE });

  const getData = () => ({
    code: code,
    game: game_id,
    server_id: server_id,
  });

  postDataWithHeaderToken(`${config.serverUrl}/redeem`, getData())
    .then(checkStatus)
    .then(parseJSON)
    .then(() => {
      const success_modal = {
        className: "",
        title: "redeem:popup_success_title",
        msg: "redeem:popup_success_msg",
        onOK: () => {
          if (typeof onSuccess === "function") onSuccess();
          dispatch(getRewardsAndTransactions());
        },
      };

      dispatch({ type: RECEIVE_POST_REDEEM_CODE });
      dispatch(showSimpleModal(success_modal));
    })
    .catch(error => {
      dispatch({ type: RECEIVE_POST_REDEEM_CODE });
      dispatch(receiveError("REDEEM", error));
      console.error(error);
    });
};

const AVAILABLE_PAGES = [
  {
    key: PAGES.REDEEM_REDEEM,
    requests: [getGames, getServersAndCharacters],
  },
  {
    key: PAGES.REDEEM_HISTORY,
    requests: [getRewardsAndTransactions],
  },
];

export const updateRedeemPage = page => dispatch => {
  const page_found = AVAILABLE_PAGES.filter(p => p.key === page).length
    ? AVAILABLE_PAGES.filter(p => p.key === page)[0]
    : null;

  if (page_found) {
    dispatch(appAction.updateCurrentPage(page));
    page_found.requests.map(request => {
      dispatch(request());
    });
  } else {
    dispatch(receiveError("REDEEM_ERR", { errMSG: "PAGE_NOT_FOUND" }));
  }
};
