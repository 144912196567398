/*!(C)2019 Rayark Inc. - All Rights Reserved
 * Rayark Confidential
 *
 * NOTICE: The intellectual and technical concepts contained herein are
 * proprietary to or under control of Rayark Inc. and its affiliates.
 * The information herein may be covered by patents, patents in process,
 * and are protected by trade secret or copyright law.
 * You may not disseminate this information or reproduce this material
 * unless otherwise prior agreed by Rayark Inc. in writing.
 */

import React from "react";
import PropTypes from "prop-types";
import { Scrollbars } from "react-custom-scrollbars";
import { ViewContainer, Avatar, UserSID } from "..";
import { FRIENDS_TAB } from "../../model/constants";
import loner_reminder from "../../assets/images/no_birds.png";

import icon_add_user from "../../assets/images/icon_addUser.png";
import icon_add_user_mobile from "../../assets/images/icon_addUser_mobile.png";
import icon_is_friend from "../../assets/images/icon_friends.png";

import "./friend.scss";
function UserCell({
  player,
  is_selected,
  is_friend = false,
  prefix,
  onClick,
  onHoverIn,
  onHoverOut,
  // handler
  onClickUnfollow = () => null,
  onClickAddUser = () => null,
  // display control
  show_unfollow = false,
  show_adduser = true,
}) {
  let btnpos_index = 0;

  return (
    <div
      key={`${prefix}_${player.sid}`}
      className={is_selected ? "friend__userCell active" : "friend__userCell"}
      onClick={onClick(player.sid)}
      onMouseEnter={onHoverIn(player.sid)}
      onMouseLeave={onHoverOut(player.sid)}>
      <Avatar avatarURL={player.avatar} />
      {show_adduser ? (
        <div className={"addFollower btnpos_" + btnpos_index++} onClick={onClickAddUser(player)}>
          <img className="pc" src={icon_add_user} />
          <img className="mobile" src={icon_add_user_mobile} />
        </div>
      ) : null}
      {show_unfollow ? (
        <div className={"unfollow btnpos_" + btnpos_index++} onClick={onClickUnfollow(player)}>
          &times;
        </div>
      ) : null}
      {player && player.sid ? (
        <UserSID SID={player.sid}>
          {is_friend ? <img className="isFriend" src={icon_is_friend} /> : ""}
        </UserSID>
      ) : null}
    </div>
  );
}

UserCell.propTypes = {
  player: PropTypes.object,
  is_selected: PropTypes.bool,
  is_friend: PropTypes.oneOfType([PropTypes.bool, PropTypes.number, PropTypes.string]),
  prefix: PropTypes.string,
  onClick: PropTypes.func,
  onHoverIn: PropTypes.func,
  onHoverOut: PropTypes.func,
  // handler
  onClickUnfollow: PropTypes.func,
  onClickAddUser: PropTypes.func,
  // display control
  show_unfollow: PropTypes.bool,
  show_adduser: PropTypes.bool,
};
function FollowList({
  friends,
  selected,
  target,
  prefix,
  title,
  onClick,
  onHoverIn,
  onHoverOut,
  onClickAddUser,
  onClickUnfollow,
  children,
}) {
  const list_cooked =
    target === FRIENDS_TAB.FOLLOWERS
      ? friends[target]
          .map(player => {
            return {
              ...player,
              is_friend: !!friends[FRIENDS_TAB.FOLLOWING].filter(
                _player => _player.sid === player.sid
              ).length,
            };
          })
          .filter(player => !player.is_friend)
      : friends[target];

  const display_list = list_cooked.map(player => (
    <UserCell
      key={player.sid}
      player={player}
      is_selected={player.sid === selected}
      is_friend={player.is_friend}
      prefix={prefix}
      show_unfollow={!!onClickUnfollow}
      show_adduser={!!onClickAddUser}
      onClick={onClick}
      onHoverIn={onHoverIn}
      onHoverOut={onHoverOut}
      {...(target === FRIENDS_TAB.FOLLOWERS
        ? {
            onClickAddUser: onClickAddUser,
            is_friend: player.is_friend,
          }
        : {
            onClickUnfollow: onClickUnfollow,
            is_friend: friends[FRIENDS_TAB.FOLLOWERS].filter(
              _follower => _follower.sid === player.sid
            ).length,
          })}
    />
  ));

  return (
    <div className="admin__panelContent admin--panel_content_right">
      <div className="admin__viewTitle">{title}</div>

      {children}

      {/* PC */}
      <Scrollbars className="admin__viewContent pc">
        <ViewContainer className="listview">{display_list}</ViewContainer>
        <div className="friend__userCell admin__bottomHint"></div>
      </Scrollbars>

      {/* Mobile */}
      <div className={`admin__viewContent mobile ${list_cooked.length ? "" : "loner"}`}>
        <img className="lonerReminder" src={loner_reminder} />
        {display_list}
      </div>
    </div>
  );
}

FollowList.propTypes = {
  friends: PropTypes.object,
  selected: PropTypes.string,
  target: PropTypes.string,
  prefix: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func,
  onHoverIn: PropTypes.func,
  onHoverOut: PropTypes.func,
  onClickAddUser: PropTypes.func,
  onClickUnfollow: PropTypes.func,
  children: PropTypes.element,
};

export default FollowList;
