/*!(C)2019 Rayark Inc. - All Rights Reserved
 * Rayark Confidential
 *
 * NOTICE: The intellectual and technical concepts contained herein are
 * proprietary to or under control of Rayark Inc. and its affiliates.
 * The information herein may be covered by patents, patents in process,
 * and are protected by trade secret or copyright law.
 * You may not disseminate this information or reproduce this material
 * unless otherwise prior agreed by Rayark Inc. in writing.
 */

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { UserSID, Avatar } from "../../components";
import { follow } from "../../actions/friends";
import { hideModal } from "../../actions/modal";
import { PAGES } from "../../model/constants";
import notFoundImg from "../../assets/images/notFoundImg.png";

const ErrorMessageBox = ({ t, errMSG, renderCloseModalBtn }) => {
  const err = `error:${errMSG}`;

  return (
    <div className="resultBox">
      <div className="resultBoxBack">
        <div className="resultContent result_search">
          <div className="resultMSG errMSG">{t(err)}</div>
          <div className="action">{renderCloseModalBtn(t("form:ok"))}</div>
        </div>
      </div>
    </div>
  );
};

ErrorMessageBox.propTypes = {
  t: PropTypes.func,
  errMSG: PropTypes.string,
  renderCloseModalBtn: PropTypes.func,
};

const NotFoundBox = ({ t, renderCloseModalBtn }) => {
  return (
    <div className="resultBox">
      <div className="resultBoxBack">
        <div className="resultContent result_search">
          <img className="notFoundImg" src={notFoundImg} />
          <div className="resultMSG">{t("profile:search_notfound")}</div>
          <div className="action">{renderCloseModalBtn(t("form:ok"))}</div>
        </div>
      </div>
    </div>
  );
};

NotFoundBox.propTypes = {
  t: PropTypes.func,
  renderCloseModalBtn: PropTypes.func,
};

const SingleSIDBox = ({
  t,
  results,
  handlePageAction,
  renderCloseModalBtn,
  getActionModalData,
  following,
  maxFollowing,
  profile,
  page,
}) => {
  const result = results[0];

  const _inspectSid = sid => {
    let is_reach_limit = false;
    let is_myself = profile.sid === sid;
    let is_already_inlist = false;

    switch (page) {
      case PAGES.FOLLOWING:
        is_reach_limit = following.length >= maxFollowing;
        is_already_inlist = following.some(player => player.sid === sid);
        break;
      default:
        break;
    }

    return { is_reach_limit, is_myself, is_already_inlist };
  };

  const modaldata_init = {
    button_text: "",
    confirm_msg: "",
    already_inlist_msg: "",
    myself_msg: "",
    reach_limit_msg: "",
  };

  const modaldata = {
    ...modaldata_init,
    ...getActionModalData(),
  };

  const { is_reach_limit, is_myself, is_already_inlist } = _inspectSid(result.sid);

  let action = (
    <div>
      <div className="resultMSG">{t(modaldata.confirm_msg)}</div>
      <div className="action">
        <div
          className="button addUser"
          onClick={() => {
            handlePageAction(result);
          }}>
          {t(modaldata.button_text)}
        </div>
        {renderCloseModalBtn(t("form:cancel"))}
      </div>
    </div>
  );

  if (is_reach_limit) {
    action = (
      <div>
        <div className="resultMSG">{t(modaldata.reach_limit_msg)}</div>
        <div className="action">{renderCloseModalBtn(t("form:ok"))}</div>
      </div>
    );
  } else if (is_myself) {
    action = (
      <div>
        <div
          className="resultMSG selfFollowing"
          dangerouslySetInnerHTML={{ __html: t(modaldata.myself_msg) }}
        />
        <div className="action">{renderCloseModalBtn(t("form:ok"))}</div>
      </div>
    );
  } else if (is_already_inlist) {
    action = (
      <div>
        <div className="resultMSG">{t(modaldata.already_inlist_msg)}</div>
        <div className="action">{renderCloseModalBtn(t("form:ok"))}</div>
      </div>
    );
  }

  return (
    <div className="resultBox">
      <div className="resultBoxBack">
        <div className="resultContent result_search">
          <div className="sid_result">
            <Avatar avatarURL={result.avatar} />
            <UserSID SID={result.sid} />
          </div>
          {action}
        </div>
      </div>
    </div>
  );
};

SingleSIDBox.propTypes = {
  t: PropTypes.func,
  results: PropTypes.arrayOf(PropTypes.object),
  handlePageAction: PropTypes.func,
  renderCloseModalBtn: PropTypes.func,
  getActionModalData: PropTypes.func,
  following: PropTypes.arrayOf(PropTypes.object),
  maxFollowing: PropTypes.number,
  profile: PropTypes.object,
  page: PropTypes.string,
};

function SearchResultInnerModal({
  t,
  dispatch,
  page,
  results,
  errMSG,
  following,
  maxFollowing,
  profile,
}) {
  const handleClose = () => {
    let box = document.getElementById("admin__leftNavPanel");
    box.classList.remove("modalShowing");
    dispatch(hideModal());
  };

  const renderCloseModalBtn = text => {
    return (
      <div className="button subBtn" onClick={handleClose}>
        {text}
      </div>
    );
  };

  const handlePageAction = player => {
    switch (page) {
      case PAGES.FOLLOWING:
      default:
        handleFollow(player);
        break;
    }
  };

  const renderMultipleSIDs = () => {
    // TODO
    console.error("STUB: SearchResultInnerModal.renderMultipleSIDs");
    return null;
  };

  const handleFollow = player => {
    // close when it is the last one to be added
    if (results.length <= 1) {
      handleClose();
    }
    dispatch(follow(player));
  };

  const getActionModalData = () => {
    let ret = {
      button_text: "",
      confirm_msg: "",
      already_inlist_msg: "",
      reach_limit_msg: "",
      myself_msg: "",
    };

    switch (page) {
      case PAGES.FOLLOWING:
      default: {
        (ret.button_text = "profile:add"),
          (ret.confirm_msg = "profile:add_confirm"),
          (ret.myself_msg = "profile:self_following"),
          (ret.reach_limit_msg = "profile:max_following"),
          (ret.already_inlist_msg = "profile:search_duplicate");
        break;
      }
    }

    return ret;
  };

  let box = document.getElementById("admin__leftNavPanel");
  box.classList.add("modalShowing");

  if (errMSG) {
    return <ErrorMessageBox t={t} errMSG={errMSG} renderCloseModalBtn={renderCloseModalBtn} />;
  }

  if (results.length === 0) {
    return <NotFoundBox t={t} renderCloseModalBtn={renderCloseModalBtn} />;
  }

  if (results.length === 1) {
    return (
      <SingleSIDBox
        t={t}
        results={results}
        handlePageAction={handlePageAction}
        renderCloseModalBtn={renderCloseModalBtn}
        getActionModalData={getActionModalData}
        following={following}
        maxFollowing={maxFollowing}
        profile={profile}
        page={page}
      />
    );
  }

  return renderMultipleSIDs();
}

SearchResultInnerModal.propTypes = {
  t: PropTypes.func,
  dispatch: PropTypes.func,
  page: PropTypes.string,
  results: PropTypes.arrayOf(PropTypes.object),
  errMSG: PropTypes.string,
  following: PropTypes.arrayOf(PropTypes.object),
  maxFollowing: PropTypes.number,
  profile: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    results: state.friends.searchResult,
    following: state.friends.following,
    maxFollowing: state.friends.maxFollowing,
    page: state.friends.showList,
    profile: state.profile,
  };
}

export default connect(mapStateToProps)(withTranslation()(SearchResultInnerModal));
