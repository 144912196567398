/*!(C)2019 Rayark Inc. - All Rights Reserved
 * Rayark Confidential
 *
 * NOTICE: The intellectual and technical concepts contained herein are
 * proprietary to or under control of Rayark Inc. and its affiliates.
 * The information herein may be covered by patents, patents in process,
 * and are protected by trade secret or copyright law.
 * You may not disseminate this information or reproduce this material
 * unless otherwise prior agreed by Rayark Inc. in writing.
 */

import { combineReducers } from "redux";
import { routerReducer as routing } from "react-router-redux";
import user from "./user";
import app from "./app";
import profile from "./profile";
import redeem from "./redeem";
import friends from "./friends";
import modal from "./modal";
import error from "./error";

export default combineReducers({
  routing,
  app,
  user,
  profile,
  friends,
  redeem,
  modal,
  error,
});
