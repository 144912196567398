/*!(C)2019 Rayark Inc. - All Rights Reserved
 * Rayark Confidential
 *
 * NOTICE: The intellectual and technical concepts contained herein are
 * proprietary to or under control of Rayark Inc. and its affiliates.
 * The information herein may be covered by patents, patents in process,
 * and are protected by trade secret or copyright law.
 * You may not disseminate this information or reproduce this material
 * unless otherwise prior agreed by Rayark Inc. in writing.
 */

export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";

export const showModal = (type, props) => {
  return {
    type: SHOW_MODAL,
    modalType: type,
    props,
  };
};

export const showCloneDoneModal = () => {
  return {
    type: SHOW_MODAL,
    modalType: "CLONE_DONE",
    props: {},
  };
};

export const showUnfollowModal = player => {
  return {
    type: SHOW_MODAL,
    modalType: "CONFIRM_UNFOLLOW",
    props: {
      player,
    },
  };
};

export const showAlertModal = ({ page, title, text }) => {
  return {
    type: SHOW_MODAL,
    modalType: "ALERT",
    props: {
      page,
      title,
      text,
    },
  };
};

export const showConfirmPlayerModal = modalData => {
  const { player, action_button_text, confirm_msg, confirm_callback } = modalData;
  return {
    type: SHOW_MODAL,
    modalType: "CONFIRM_PLAYER",
    props: {
      player,
      action_button_text,
      confirm_msg,
      onOK: confirm_callback,
    },
  };
};

export const showSimpleModal = modalData => {
  const { className, title, msg, onOK } = modalData;
  return {
    type: SHOW_MODAL,
    modalType: "SIMPLE_MSG",
    props: {
      className,
      title,
      msg,
      onOK,
    },
  };
};

export const hideModal = () => {
  return {
    type: HIDE_MODAL,
  };
};
