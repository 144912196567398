/*!(C)2019 Rayark Inc. - All Rights Reserved
 * Rayark Confidential
 *
 * NOTICE: The intellectual and technical concepts contained herein are
 * proprietary to or under control of Rayark Inc. and its affiliates.
 * The information herein may be covered by patents, patents in process,
 * and are protected by trade secret or copyright law.
 * You may not disseminate this information or reproduce this material
 * unless otherwise prior agreed by Rayark Inc. in writing.
 */

import {
  REQUEST_GAMES,
  RECEIVE_GAMES,
  REQUEST_SERVERS_AND_CHARACTERS,
  RECEIVE_SERVERS_AND_CHARACTERS,
  REQUEST_REDEEM_TRANSACTIONS,
  RECEIVE_REDEEM_TRANSACTIONS,
  REQUEST_REDEEM_REWARDS,
  RECEIVE_REDEEM_REWARDS,
  REQUEST_POST_REDEEM_CODE,
  RECEIVE_POST_REDEEM_CODE,
} from "../actions/redeem";

const init_state = {
  games: {},
  serversAndCharacters: {},
  rewards: {},
  transactions: [],
};

export default function RedeemReducer(state = init_state, action) {
  switch (action.type) {
    case RECEIVE_GAMES:
      return {
        ...state,
        games: action.games,
      };
    case RECEIVE_SERVERS_AND_CHARACTERS:
      return {
        ...state,
        serversAndCharacters: action.serversAndCharacters,
      };
    case RECEIVE_REDEEM_REWARDS:
      return {
        ...state,
        rewards: action.rewards,
      };
    case RECEIVE_REDEEM_TRANSACTIONS:
      return {
        ...state,
        transactions: action.transactions,
      };
    case REQUEST_GAMES:
    case REQUEST_SERVERS_AND_CHARACTERS:
    case REQUEST_REDEEM_REWARDS:
    case REQUEST_REDEEM_TRANSACTIONS:
    case REQUEST_POST_REDEEM_CODE:
    case RECEIVE_POST_REDEEM_CODE:
    default:
      return state;
  }
}
