/*!(C)2019 Rayark Inc. - All Rights Reserved
 * Rayark Confidential
 *
 * NOTICE: The intellectual and technical concepts contained herein are
 * proprietary to or under control of Rayark Inc. and its affiliates.
 * The information herein may be covered by patents, patents in process,
 * and are protected by trade secret or copyright law.
 * You may not disseminate this information or reproduce this material
 * unless otherwise prior agreed by Rayark Inc. in writing.
 */

export const LANGUAGES = ["zh", "en", "ja", "ko", "th", "vi", "es", "pt", "ru", "de", "fr"];
export const LONG_LANGUAGES = ["th", "vi", "es", "pt", "ru", "de", "fr"];
export const LANG_DISPLAY = {
  zh: {
    name: "繁體中文",
    loginBtnStyles: ["xs"],
  },
  en: {
    name: "English",
    loginBtnStyles: ["sm"],
  },
  ja: {
    name: "日本語",
    loginBtnStyles: ["xs"],
  },
  ko: {
    name: "한국어",
    loginBtnStyles: ["xxs"],
  },
  th: {
    name: "Thai",
    loginBtnStyles: ["fixed", "md"],
  },
  vi: {
    name: "Vietnamese",
    loginBtnStyles: ["fixed", "sm"],
  },
  es: {
    name: "Spanish",
    loginBtnStyles: ["fixed", "lg"],
  },
  pt: {
    name: "Portuguese",
    loginBtnStyles: ["fixed", "xl"],
  },
  ru: {
    name: "Russian",
    loginBtnStyles: ["fixed", "sm"],
  },
  de: {
    name: "German",
    loginBtnStyles: ["fixed", "md"],
  },
  fr: {
    name: "French",
    loginBtnStyles: ["fixed", "lg"],
  },
};

export const FRIENDS_TAB = {
  FOLLOWERS: "followers",
  FOLLOWING: "following",
};

export const PAGES = {
  FOLLOWING: "friend_following",
  FOLLOWERS: "friend_followers",
  REDEEM_REDEEM: "redeem_redeem",
  REDEEM_HISTORY: "redeem_history",
  MY_GAMES_RECORDS: "my_games_records",
};

export const SEGMENT_KEY = {
  FRIEND: "segment_friends",
  REDEEM: "segment_redeem",
  MY_GAMES: "segment_my_games",
};

export const SEGMENTS = [
  {
    key: SEGMENT_KEY.FRIEND,
    path: "/profile",
    default_page: PAGES.FOLLOWING,
  },
  {
    key: SEGMENT_KEY.REDEEM,
    path: "/redeem",
    default_page: PAGES.REDEEM_REDEEM,
    hide_for_languages: ["ja"],
  },
  {
    key: SEGMENT_KEY.MY_GAMES,
    path: "/my-games",
    default_page: PAGES.MY_GAMES_RECORDS,
  },
];

export const USER_CREDENTIAL_TYPE = {
  FACEBOOK: "USER_CREDENTIAL_FACEBOOK",
  EMAIL: "USER_CREDENTIAL_EMAIL",
  TWITTER: "USER_CREDENTIAL_TWITTER",
  GOOGLE: "USER_CREDENTIAL_GOOGLE",
  UNKNOWN: "USER_CREDENTIAL_UNKNOWN",
};

export const CHECK_LOGIN_PAGES = {
  ADMIN: "ADMIN",
  SETTING: "SETTING",
  LOGIN: "LOGIN",
  REGISTER: "REGISTER",
};

export const FIELD_TYPE = {
  SELECT: "FIELD_SELECT",
  TEXT: "FIELD_TEXT",
  NUMBER: "FIELD_NUMBER",
  EMAIL: "FIELD_EMAIL",
};

export const NAV_OPTIONS = {
  NONE: "NONE",
  BACK_AND_NONE: "BACK_AND_NONE",
  BACK_AND_REG: "BACK_AND_REG",
  BACK_AND_LOGIN: "BACK_AND_LOGIN",
  MENU_AND_LOGIN: "MENU_AND_LOGIN",
  MENU_AND_SETTING: "MENU_AND_SETTING",
};
