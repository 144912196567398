/*!(C)2021 Rayark Inc. - All Rights Reserved
 * Rayark Confidential
 *
 * NOTICE: The intellectual and technical concepts contained herein are
 * proprietary to or under control of Rayark Inc. and its affiliates.
 * The information herein may be covered by patents, patents in process,
 * and are protected by trade secret or copyright law.
 * You may not disseminate this information or reproduce this material
 * unless otherwise prior agreed by Rayark Inc. in writing.
 */

import React from "react";
import PropTypes from "prop-types";

import "./field.scss";

function TextInput({ className = "", disabled, placeholder = "", value, onChange }) {
  return (
    <input
      className={`field__input ${className}`}
      type="text"
      disabled={disabled}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
    />
  );
}

TextInput.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

function DropdownInput({
  className = "",
  disabled,
  title = "",
  placeholder = "",
  value,
  renderAsSelect,
  onChange,
  options = [],
}) {
  return renderAsSelect ? (
    <div className={`field__input field__dropdown field--as-select ${className}`}>
      <select
        value={value ?? "__undefined__"}
        disabled={disabled}
        placeholder={placeholder}
        onChange={e => {
          onChange(e.target.value);
        }}>
        {placeholder ? (
          <option value="__undefined__" disabled>
            {placeholder}
          </option>
        ) : null}
        {options.map(option => (
          <option key={`option_${option.value}`} value={option.value}>
            {option.name ?? option.value}
          </option>
        ))}
      </select>
      <div className="field__caret"></div>
    </div>
  ) : (
    <div>
      <div className={`field__input field__dropdown ${className}`} disabled={disabled}>
        <div>{title}</div>
        <div className="field__caret"></div>
      </div>
    </div>
  );
}

DropdownInput.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  title: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  renderAsSelect: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.object),
};

export { TextInput, DropdownInput };
