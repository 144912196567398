/*!(C)2019 Rayark Inc. - All Rights Reserved
 * Rayark Confidential
 *
 * NOTICE: The intellectual and technical concepts contained herein are
 * proprietary to or under control of Rayark Inc. and its affiliates.
 * The information herein may be covered by patents, patents in process,
 * and are protected by trade secret or copyright law.
 * You may not disseminate this information or reproduce this material
 * unless otherwise prior agreed by Rayark Inc. in writing.
 */

import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import * as config from "../../variables";
import { getSegmentDataModel } from "../../model/helper";
import { SEGMENT_KEY } from "../../model/constants";
import CloseImg from "../../assets/images/nav_close.png";
import LangImg from "../../assets/images/nav_lang.png";
import LogoImg from "../../assets/images/nav_logo.png";
import MapImg from "../../assets/images/nav_map.png";
import ProfileImg from "../../assets/images/nav_profile.png";
import SNSImg from "../../assets/images/nav_sns.png";

class MobileMenu extends React.Component {
  onCloseEvent() {
    const target = document.getElementById("mobileMenuPage");
    target.classList.remove("active");
  }

  render() {
    const { t, i18n, profile, children } = this.props;
    const termPage = config.termPage[i18n.language] || config.termPage.en;

    const renderRedeemLink = () => {
      const segment = getSegmentDataModel(SEGMENT_KEY.REDEEM);
      const hide_for_languages = segment.hide_for_languages || [];
      return i18n.language && !hide_for_languages.includes(i18n.language) ? (
        <Link to="/redeem">{t("common:redeem")}</Link>
      ) : null;
    };

    return (
      <div className="mobileMenu__page mobile" id="mobileMenuPage">
        <div className="mobileMenu__background" onClick={this.onCloseEvent.bind(this)}></div>
        <div className="mobileMenu__lists">
          <div className="mobileMenu__list" id="mobileMenu__closeBar">
            <div className="mobileMenu__icon" onClick={this.onCloseEvent.bind(this)}>
              <img src={CloseImg} />
            </div>
            <div className="mobileMenu__button">
              {profile ? (
                <Link to="/logout">{t("common:logout")}</Link>
              ) : (
                <Link to="/login">{t("common:login")}</Link>
              )}
            </div>
          </div>
          {profile ? (
            <div className="mobileMenu__list" id="mobileMenu__profileBar">
              <div className="mobileMenu__icon">
                <img src={ProfileImg} />
              </div>
              <Link to="/profile">{t("common:profile")}</Link>
              {renderRedeemLink()}
              <Link to="/my-games">{t("common:my_games")}</Link>
            </div>
          ) : null}
          <div className="mobileMenu__list" id="mobileMenu__mapBar">
            <div className="mobileMenu__icon">
              <img src={MapImg} />
            </div>
            <a href={termPage} target="_blank" rel="noopener noreferrer">
              {t("common:privacy")}
            </a>
            <a href={termPage} target="_blank" rel="noopener noreferrer">
              {t("common:user_term")}
            </a>
            <a href={config.HRPage} target="_blank" rel="noopener noreferrer">
              {t("common:hr")}
            </a>
          </div>
          <div className="mobileMenu__list" id="mobileMenu__snsBar">
            <div className="mobileMenu__icon">
              <img src={SNSImg} />
            </div>
            <a href={config.rayarkYT} target="_blank" rel="noopener noreferrer">
              Youtube
            </a>
            <a href={config.rayarkFB} target="_blank" rel="noopener noreferrer">
              Facebook
            </a>
          </div>
          <div className="mobileMenu__list" id="mobileMenu__langBar">
            <div className="mobileMenu__icon">
              <img src={LangImg} />
            </div>
            {children}
          </div>
          <div className="mobileMenu__list" id="mobileMenu__copyBar">
            <div className="mobileMenu__icon">
              <img src={LogoImg} />
              <Link to="/"></Link>
            </div>
            <div>ALL RIGHTS RESERVED.,2017, RAYARK INC.</div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    profile: state.profile,
  };
}

MobileMenu.propTypes = {
  t: PropTypes.func,
  i18n: PropTypes.object,
  profile: PropTypes.object,
  children: PropTypes.node,
};

export default connect(mapStateToProps)(withTranslation()(MobileMenu));
