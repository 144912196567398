/*!(C)2019 Rayark Inc. - All Rights Reserved
 * Rayark Confidential
 *
 * NOTICE: The intellectual and technical concepts contained herein are
 * proprietary to or under control of Rayark Inc. and its affiliates.
 * The information herein may be covered by patents, patents in process,
 * and are protected by trade secret or copyright law.
 * You may not disseminate this information or reproduce this material
 * unless otherwise prior agreed by Rayark Inc. in writing.
 */

import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import rayarkpassLogoLarge from "../../assets/images/rayarkpassLOGO.png";
import "./banner.scss";

function Banner({ t, profile, redeem }) {
  return (
    <div className="banner__slide banner__slide--0">
      <div className="banner__fixedItem">
        <img className="banner__logo" src={rayarkpassLogoLarge} />
        <div className="banner__text banner__text--0">
          <div className="banner__mainText">is now on!</div>
          <div className="banner__subText">{t("index:banner_text_start_tour")}</div>
        </div>
        {redeem && profile ? (
          <div className="banner__button">
            <Link to="/my-games" />
            <span>{t("profile")}</span>
          </div>
        ) : (
          <div>
            <div className="banner__button">
              <Link to="/register" />
              <span style={{ fontSize: "18px" }}>Register Now!</span>
            </div>
            <div className="banner__loginHint">
              {t("index:already_have_an_account")} <Link to="/login">{t("common:login")}</Link> !
            </div>
          </div>
        )}
      </div>

      {/* index page background */}
      <div className="banner__page banner__page--0" />
    </div>
  );
}

Banner.propTypes = {
  t: PropTypes.func,
  profile: PropTypes.object,
  redeem: PropTypes.object,
};

export default Banner;
