/*!(C)2019 Rayark Inc. - All Rights Reserved
 * Rayark Confidential
 *
 * NOTICE: The intellectual and technical concepts contained herein are
 * proprietary to or under control of Rayark Inc. and its affiliates.
 * The information herein may be covered by patents, patents in process,
 * and are protected by trade secret or copyright law.
 * You may not disseminate this information or reproduce this material
 * unless otherwise prior agreed by Rayark Inc. in writing.
 */

import React from "react";
import PropTypes from "prop-types";
import { LeftNavPanel } from "../../components";
import * as friendActions from "../../actions/friends";
import { PAGES } from "../../model/constants";
import FriendView from "./FriendView";

import icon_following from "../../assets/images/icon_following.png";
import icon_following_on from "../../assets/images/icon_following_on.png";
import icon_followers from "../../assets/images/icon_followers.png";
import icon_followers_on from "../../assets/images/icon_followers_on.png";

function FriendSegment({ t, dispatch, app, friends, profile }) {
  const getGroupLength = () => {
    const mutual_follow = friends.followers.filter(follower => {
      return friends.following.filter(following => following.sid === follower.sid).length > 0;
    });

    return {
      [PAGES.FOLLOWING]: friends.following.length,
      [PAGES.FOLLOWERS]: friends.followers.length - mutual_follow.length,
    };
  };

  const switchToPage = target => {
    dispatch(friendActions.updateFriendPage(target));
  };

  const getNavs = () => {
    const group_length = getGroupLength();
    const getBadgeByKey = key => group_length[key];

    const navs = [
      {
        key: PAGES.FOLLOWING,
        name: "Following",
        icon: { off: icon_following, on: icon_following_on },
        badge: getBadgeByKey(PAGES.FOLLOWING),
        onClick: switchToPage.bind(this, PAGES.FOLLOWING),
      },
      {
        key: PAGES.FOLLOWERS,
        name: "Followers",
        icon: {
          off: icon_followers,
          on: icon_followers_on,
          style: { verticalAlign: "middle", marginTop: "-10px" },
        },
        badge: getBadgeByKey(PAGES.FOLLOWERS),
        onClick: switchToPage.bind(this, PAGES.FOLLOWERS),
      },
    ];

    return navs;
  };

  return !!app.segment && !!app.page ? (
    <LeftNavPanel
      navs={getNavs()}
      active_key={app.page}
      show_badge={true}
      small_icon={false}
      show_icon={true}>
      <FriendView t={t} dispatch={dispatch} page={app.page} profile={profile} friends={friends} />
    </LeftNavPanel>
  ) : null;
}

FriendSegment.propTypes = {
  t: PropTypes.func,
  dispatch: PropTypes.func,
  app: PropTypes.object,
  friends: PropTypes.object,
  profile: PropTypes.object,
};

export default FriendSegment;
