/*!(C)2019 Rayark Inc. - All Rights Reserved
 * Rayark Confidential
 *
 * NOTICE: The intellectual and technical concepts contained herein are
 * proprietary to or under control of Rayark Inc. and its affiliates.
 * The information herein may be covered by patents, patents in process,
 * and are protected by trade secret or copyright law.
 * You may not disseminate this information or reproduce this material
 * unless otherwise prior agreed by Rayark Inc. in writing.
 */

import { RECEIVE_ERROR, CLEAR_ERROR } from "../actions/error";

const init_state = {};

export default function errorReducer(state = init_state, action) {
  switch (action.type) {
    case RECEIVE_ERROR: {
      const { key, msg } = action;
      let newState = { ...state };
      newState[key] = msg;
      return newState;
    }
    case CLEAR_ERROR:
    case "@@router/LOCATION_CHANGE":
      return { ...init_state };
    default:
      return state;
  }
}
