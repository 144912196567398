/*!(C)2019 Rayark Inc. - All Rights Reserved
 * Rayark Confidential
 *
 * NOTICE: The intellectual and technical concepts contained herein are
 * proprietary to or under control of Rayark Inc. and its affiliates.
 * The information herein may be covered by patents, patents in process,
 * and are protected by trade secret or copyright law.
 * You may not disseminate this information or reproduce this material
 * unless otherwise prior agreed by Rayark Inc. in writing.
 */

import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import FontAwesome from "react-fontawesome";
import * as config from "../../variables";
import rayarkpassLOGOCopyright from "../../assets/images/rayarkpassLOGO_copyright.png";

import "./footer.scss";

function Footer({ t, lang, setBackground }) {
  const external_links = [
    {
      href: config.termPage[lang] || config.termPage.en,
      name: t("common:privacy"),
    },
    {
      href: config.termPage[lang] || config.termPage.en,
      name: t("common:user_term"),
    },
    {
      href: config.HRPage,
      name: t("common:hr"),
    },
  ];

  return (
    <footer className={`footer ${setBackground ? "footer--showBG" : ""}`}>
      <div className="footer__content">
        <div className="footer__gear footer__logo">
          <Link to="/">
            <img src={rayarkpassLOGOCopyright} />
          </Link>
        </div>
        <div className="footer__gear footer__menubar">
          <div className="footer__text">ABOUT THIS SITE:</div>
          <div className="footer__wrapper">
            {external_links.map((link, index) => (
              <a
                key={`link_${index}`}
                className="footer__btn"
                href={link.href}
                target="_blank"
                rel="noopener noreferrer">
                {link.name}
              </a>
            ))}
          </div>
        </div>
        <div className="footer__gear footer__follows">
          <div className="footer__text">FOLLOWS US ON:</div>
          <div>
            <a
              className="footer__btn"
              href={config.rayarkFB}
              target="_blank"
              rel="noopener noreferrer">
              <FontAwesome name="facebook-official" />
            </a>
            <a
              className="footer__btn"
              href={config.rayarkYT}
              target="_blank"
              rel="noopener noreferrer">
              <FontAwesome name="youtube-play" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  t: PropTypes.func,
  lang: PropTypes.string,
  setBackground: PropTypes.bool,
};

export default Footer;
