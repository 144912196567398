/*!(C)2019 Rayark Inc. - All Rights Reserved
 * Rayark Confidential
 *
 * NOTICE: The intellectual and technical concepts contained herein are
 * proprietary to or under control of Rayark Inc. and its affiliates.
 * The information herein may be covered by patents, patents in process,
 * and are protected by trade secret or copyright law.
 * You may not disseminate this information or reproduce this material
 * unless otherwise prior agreed by Rayark Inc. in writing.
 */

import "react-hot-loader"; // should be required before react and react-dom
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { Provider } from "react-redux";
import { I18nextProvider } from "react-i18next";
import store from "./store";
import history from "./history";
import i18n from "./i18n";
import { INDEPENDENT_ROUTES, APP_ROUTES } from "./model/mainroutes";
import App from "./containers/App";
import ErrorBoundary from "./containers/ErrorBoundary";
import { Loading } from "./components";

import "./assets/scss/main.scss";
import "./assets/css/font-awesome.min.css";
import "./assets/css/min.css";

const renderApp = () => {
  return (
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <Router history={history}>
          <ErrorBoundary>
            <Suspense fallback={<Loading />}>
              <Switch>
                {INDEPENDENT_ROUTES.map((route, index) => {
                  return (
                    <Route
                      key={index}
                      path={route.path}
                      component={route.component}
                      exact={route.exact}
                    />
                  );
                })}
                <App>
                  <Switch>
                    {/* for rayark pass web */}
                    {APP_ROUTES.map((route, index) => {
                      return (
                        <Route
                          key={index}
                          path={route.path}
                          component={route.component}
                          exact={route.exact}
                        />
                      );
                    })}
                    <Redirect from="*" to="/" />
                  </Switch>
                </App>
              </Switch>
            </Suspense>
          </ErrorBoundary>
        </Router>
      </Provider>
    </I18nextProvider>
  );
};

ReactDOM.render(renderApp(), document.getElementById("root"));

if (module.hot) {
  module.hot.accept();
}
