/*!(C)2021 Rayark Inc. - All Rights Reserved
 * Rayark Confidential
 *
 * NOTICE: The intellectual and technical concepts contained herein are
 * proprietary to or under control of Rayark Inc. and its affiliates.
 * The information herein may be covered by patents, patents in process,
 * and are protected by trade secret or copyright law.
 * You may not disseminate this information or reproduce this material
 * unless otherwise prior agreed by Rayark Inc. in writing.
 */

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { UserSID } from "../../components";
import { Dialog, DialogSubtitle, DialogContent, DialogFooter } from "../../layouts";
import googlePlayIcon from "../../assets/images/icon_google_play_white.png";
import checkIcon from "../../assets/images/icon_check.png";
import bindIcon from "../../assets/images/btn_bind.png";
import FontAwesome from "react-fontawesome";
import { showSimpleModal } from "../../actions/modal";
import { bindAccount } from "../../actions/profile";
import URLSearchParams from "core-js/features/url-search-params";
import "./setting.scss";

const THIRD_PARTY = {
  FACEBOOK: "Facebook",
  TWITTER: "Twitter",
  GOOGLE: "Google",
  APPLE: "Apple",
};

const SettingIndex = ({ t, dispatch, profile }) => {
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const bindingError = window.sessionStorage.getItem("binding_error");
    // check if query string include error after account binding
    if (urlSearchParams.has("error")) {
      const error = urlSearchParams.get("error");
      window.sessionStorage.setItem("binding_error", error);
    }
    // show account binding error after login check
    if (bindingError) {
      dispatch(
        showSimpleModal({
          title: "error:INVALID_OPERATION",
          msg: bindingError === "INVALID_BINDING" ? "error:INVALID_ACCOUNT_BINDING" : "",
          onOK: () => {
            window.sessionStorage.removeItem("binding_error");
          },
        })
      );
    }
  });

  const getAccountName = () => {
    const { credentials } = profile || {};

    if (credentials.facebook) {
      return (
        (credentials.facebook.name || t("profile:no_acount_name")) + ` (${THIRD_PARTY.FACEBOOK})`
      );
    } else if (credentials.twitter) {
      return (
        (credentials.twitter.name || t("profile:no_acount_name")) + ` (${THIRD_PARTY.TWITTER})`
      );
    } else if (credentials.google) {
      return (credentials.google.name || t("profile:no_acount_name")) + ` (${THIRD_PARTY.GOOGLE})`;
    } else if (credentials.apple) {
      return (credentials.apple.name || t("profile:no_acount_name")) + ` (${THIRD_PARTY.APPLE})`;
    }
  };

  const getAccountAndButtons = () => {
    let accountName = "";
    let editBtnPc = (
      <Link to="/setting#pwd" className="setting__sectionEditBtn pc">
        {t("form:edit")}
      </Link>
    );
    let editBtnMobile = (
      <Link to="/setting#pwd" className="setting__sectionEditBtnMobile">
        {t("form:reset_success_title")}
      </Link>
    );

    const { credentials } = profile || {};
    if (!profile || !credentials) {
      return;
    }

    if (credentials.email) {
      accountName = credentials.email.email;
    } else {
      accountName = getAccountName();
      editBtnPc = null;
      editBtnMobile = null;
    }

    return {
      accountName: accountName,
      editBtnPc: editBtnPc,
      editBtnMobile: editBtnMobile,
    };
  };

  const { credentials } = profile || {};
  const { accountName, editBtnPc, editBtnMobile } = getAccountAndButtons() || {};
  const bindingHintBtn = (
    <div
      className="setting__bindingHintBtn"
      onClick={() =>
        dispatch(
          showSimpleModal({
            className: "setting__bindingHintModal",
            title: t("form:account_binding_title"),
            msg: t("form:account_binding_hint"),
            onOK: null,
          })
        )
      }>
      <FontAwesome name="question" />
    </div>
  );

  const handleBindAccount = target => {
    switch (target) {
      case THIRD_PARTY.APPLE:
        dispatch(bindAccount("apple"));
        return;
      case THIRD_PARTY.GOOGLE:
        dispatch(bindAccount("google"));
        return;
      case THIRD_PARTY.FACEBOOK:
        dispatch(bindAccount("facebook"));
        return;
      case THIRD_PARTY.TWITTER:
        dispatch(bindAccount("twitter"));
        return;
      default:
        return;
    }
  };

  const sections = [
    {
      title: t("form:setting_title"),
      editBtnPc: null,
      editBtnMobile: null,
      hintBtn: null,
      fields: [
        {
          title: "SID",
          className: "setting__settingBlock--id",
          value: profile && profile.sid ? <UserSID SID={profile.sid} /> : "",
          editBtn:
            profile && !!profile.allow_modify_sid ? (
              <Link to="/setting#sid" className="setting__editBtn setting__fieldEditBtn">
                {t("form:edit")}
              </Link>
            ) : null,
        },
      ],
    },
    {
      title: t("form:account_setting_title"),
      editBtnPc: editBtnPc,
      editBtnMobile: <div className="sectionLink mobile">{editBtnMobile}</div>,
      hintBtn: null,
      fields: [
        {
          title: t("profile:account"),
          className: "",
          value: accountName,
          editBtn: null,
        },
      ],
    },
    {
      title: t("form:account_binding_title"),
      editBtnPc: null,
      editBtnMobile: null,
      hintBtn: bindingHintBtn,
      fields: [
        {
          title: null,
          className: null,
          value: (
            <div className="setting__bindingOption">
              <FontAwesome className="setting__bindingOptionIcon" name="apple" />
              <span>
                Apple
                <span className="setting__bindingInfo">
                  {credentials && credentials.apple ? ` (${credentials.apple.id})` : ""}
                </span>
              </span>
            </div>
          ),
          editBtn:
            credentials && credentials.apple ? (
              <div className="setting__bindedIcon">
                <img src={checkIcon} />
              </div>
            ) : (
              <a
                className="setting__fieldEditBtn"
                onClick={() => handleBindAccount(THIRD_PARTY.APPLE)}>
                <img src={bindIcon} alt="account-binding" />
              </a>
            ),
        },
        {
          title: null,
          className: null,
          value: (
            <div className="setting__bindingOption">
              <span className="setting__bindingOptionIcon">
                <img src={googlePlayIcon} alt="account-binding" />
              </span>
              <span>
                Google Play
                <span className="setting__bindingInfo">
                  {credentials && credentials.google ? ` (${credentials.google.name})` : ""}
                </span>
              </span>
            </div>
          ),
          editBtn:
            credentials && credentials.google ? (
              <div className="setting__bindedIcon">
                <img src={checkIcon} />
              </div>
            ) : (
              <a
                className="setting__fieldEditBtn"
                onClick={() => handleBindAccount(THIRD_PARTY.GOOGLE)}>
                <img src={bindIcon} alt="account-binding" />
              </a>
            ),
        },
        {
          title: null,
          className: null,
          value: (
            <div className="setting__bindingOption">
              <FontAwesome className="setting__bindingOptionIcon" name="facebook" />
              <span>
                Facebook
                <span className="setting__bindingInfo">
                  {credentials && credentials.facebook ? ` (${credentials.facebook.name})` : ""}
                </span>
              </span>
            </div>
          ),
          editBtn:
            credentials && credentials.facebook ? (
              <div className="setting__bindedIcon">
                <img src={checkIcon} />
              </div>
            ) : (
              <a
                className="setting__fieldEditBtn"
                onClick={() => handleBindAccount(THIRD_PARTY.FACEBOOK)}>
                <img src={bindIcon} alt="account-binding" />
              </a>
            ),
        },
        {
          title: null,
          className: null,
          value: (
            <div className="setting__bindingOption">
              <FontAwesome className="setting__bindingOptionIcon" name="twitter" />
              <span>
                Twitter
                <span className="setting__bindingInfo">
                  {credentials && credentials.twitter ? ` (${credentials.twitter.name})` : ""}
                </span>
              </span>
            </div>
          ),
          editBtn:
            credentials && credentials.twitter ? (
              <div className="setting__bindedIcon">
                <img src={checkIcon} />
              </div>
            ) : (
              <a
                className="setting__fieldEditBtn"
                onClick={() => handleBindAccount(THIRD_PARTY.TWITTER)}>
                <img src={bindIcon} alt="account-binding" />
              </a>
            ),
        },
      ],
    },
  ];

  return (
    <>
      <Dialog className="setting__settingBox">
        <DialogContent>
          {sections.map((section, idx) => (
            <div className="setting__settingSection" key={`index_${idx}`}>
              <DialogSubtitle
                subtitle={section.title}
                buttons={[section.hintBtn, section.editBtnPc]}
              />
              {section.fields.map((field, _idx) => (
                <div
                  className={`setting__settingBlock blockBGcolor ${field.className}`}
                  key={`index_${_idx}`}>
                  {field.title ? <div className="setting__title">{field.title}</div> : null}
                  <div className="setLine">
                    <span>{field.value}</span>
                    {field.editBtn}
                  </div>
                </div>
              ))}
              {section.editBtnMobile}
            </div>
          ))}
        </DialogContent>

        <Link to="/setting#delete-account" className="setting__hint ">{t("profile:want_delete_account")}</Link>

        <DialogFooter>
          <Link to="/profile" className="button backBtn pc">
            {t("return")}
          </Link>
        </DialogFooter>
      </Dialog>
    </>
  );
};

SettingIndex.propTypes = {
  t: PropTypes.func,
  dispatch: PropTypes.func,
  profile: PropTypes.object,
};

export default SettingIndex;
