/*!(C)2021 Rayark Inc. - All Rights Reserved
 * Rayark Confidential
 *
 * NOTICE: The intellectual and technical concepts contained herein are
 * proprietary to or under control of Rayark Inc. and its affiliates.
 * The information herein may be covered by patents, patents in process,
 * and are protected by trade secret or copyright law.
 * You may not disseminate this information or reproduce this material
 * unless otherwise prior agreed by Rayark Inc. in writing.
 */

import React from "react";
import PropTypes from "prop-types";

import "./dialog.scss";

const Dialog = ({ className = "", children }) => {
  return <div className={`dialog ${className}`}>{children}</div>;
};

Dialog.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

const DialogTitle = ({ title = "", pcTitle = "", mobileTitle = "", className = "" }) => {
  return (
    <>
      {title ? <div className={`dialog__title ${className}`}>{mobileTitle}</div> : null}
      {pcTitle ? <div className={`dialog__title pc ${className}`}>{pcTitle}</div> : null}
      {mobileTitle ? (
        <div className={`dialog__title mobile ${className}`}>{mobileTitle}</div>
      ) : null}
    </>
  );
};

DialogTitle.propTypes = {
  title: PropTypes.string,
  pcTitle: PropTypes.string,
  mobileTitle: PropTypes.string,
  className: PropTypes.string,
};

const DialogSubtitle = ({
  subtitle = "",
  pcSubtitle = "",
  mobileSubtitle = "",
  buttons = [],
  className = "",
}) => {
  return (
    <>
      {subtitle ? (
        <div className={`dialog__subtitle ${className}`}>
          {subtitle}
          {buttons.map((button, id) => (
            <React.Fragment key={id}>{button}</React.Fragment>
          ))}
        </div>
      ) : null}
      {pcSubtitle ? <div className={`dialog__subtitle pc ${className}`}>{pcSubtitle}</div> : null}
      {mobileSubtitle ? (
        <div className={`dialog__subtitle mobile ${className}`}>{mobileSubtitle}</div>
      ) : null}
    </>
  );
};

DialogSubtitle.propTypes = {
  subtitle: PropTypes.string,
  pcSubtitle: PropTypes.string,
  mobileSubtitle: PropTypes.string,
  buttons: PropTypes.arrayOf(PropTypes.node),
  className: PropTypes.string,
};

const DialogContent = ({ id = "", className = "", children, ...props }) => {
  const select_keys = ["id"];
  const props_select = select_keys
    .filter(key => props[key] !== undefined)
    .map(key => {
      let o = {};
      o[key] = props[key];
      return o;
    })
    .reduce((l, r) => ({ ...l, ...r }), {}); // array to dictionary

  return (
    <div id={id} className={`dialog__content ${className}`} {...props_select}>
      {children}
    </div>
  );
};

DialogContent.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
};

const DialogFooter = ({ className, children }) => {
  return <div className={`dialog__footer ${className}`}>{children}</div>;
};

DialogFooter.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export { Dialog, DialogTitle, DialogSubtitle, DialogContent, DialogFooter };
