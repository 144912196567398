/*!(C)2019 Rayark Inc. - All Rights Reserved
 * Rayark Confidential
 *
 * NOTICE: The intellectual and technical concepts contained herein are
 * proprietary to or under control of Rayark Inc. and its affiliates.
 * The information herein may be covered by patents, patents in process,
 * and are protected by trade secret or copyright law.
 * You may not disseminate this information or reproduce this material
 * unless otherwise prior agreed by Rayark Inc. in writing.
 */

import {
  UPDATE_CURRENT_PAGE,
  UPDATE_CURRENT_SEGMENT,
  UPDATE_CURRENT_SEGMENT_AND_PAGE,
} from "../actions/app";

import { SET_AUTH_PROVIDE_MODE, UNSET_AUTH_PROVIDE_MODE } from "../actions/auth";

import { PAGES, SEGMENT_KEY } from "../model/constants";

const init_state = {
  segment: SEGMENT_KEY.FRIEND,
  page: PAGES.FOLLOWING,
  auth_provide_mode: false,
};

export default function appReducer(state = init_state, action) {
  switch (action.type) {
    case UPDATE_CURRENT_PAGE:
      return {
        ...state,
        page: action.page,
      };
    case UPDATE_CURRENT_SEGMENT:
      return {
        ...state,
        segment: action.segment,
      };
    case UPDATE_CURRENT_SEGMENT_AND_PAGE:
      return {
        ...state,
        segment: action.segment,
        page: action.page,
      };
    case SET_AUTH_PROVIDE_MODE:
      return {
        ...state,
        auth_provide_mode: true,
      };
    case UNSET_AUTH_PROVIDE_MODE:
      return {
        ...state,
        auth_provide_mode: false,
      };
    default:
      return state;
  }
}
