/*!(C)2019 Rayark Inc. - All Rights Reserved
 * Rayark Confidential
 *
 * NOTICE: The intellectual and technical concepts contained herein are
 * proprietary to or under control of Rayark Inc. and its affiliates.
 * The information herein may be covered by patents, patents in process,
 * and are protected by trade secret or copyright law.
 * You may not disseminate this information or reproduce this material
 * unless otherwise prior agreed by Rayark Inc. in writing.
 */

import { logout } from "./user";
import { push } from "react-router-redux";
import { showModal, showAlertModal, showSimpleModal, hideModal } from "./modal";

export const RECEIVE_ERROR = "RECEIVE_ERROR";
export const CLEAR_ERROR = "CLEAR_ERROR";

export const showError = (key, msg) => dispatch => {
  switch (key) {
    case "TOKEN": {
      const redirect_modal = {
        className: "",
        title: "ERROR",
        msg: `error:${msg}`,
        onOK: () => {
          dispatch(logout());
        },
      };
      dispatch(showSimpleModal(redirect_modal));
      break;
    }
    case "modal": {
      dispatch(showModal("ERROR", { msg }));
      break;
    }
    case "UPDATE_SID": {
      dispatch(showModal("UPDATE_SID", { errMSG: msg }));
      break;
    }
    case "FRIENDS_ERR": {
      dispatch(showModal("FRIENDS_ERR", { errMSG: msg }));
      break;
    }
    case "UPDATEPWD": {
      dispatch(
        showAlertModal({
          page: "profilePWD reset reset_fail",
          title: "form:reset_success_title",
          text: `error:${msg}`,
        })
      );
      break;
    }
    case "UPDATEINFO": {
      dispatch(
        showAlertModal({
          page: "profilePWD reset reset_fail",
          title: "error:INVALID_OPERATION",
          text: "common:contact_customer_service",
        })
      );
      break;
    }
    case "GAMESERVER": {
      switch (msg) {
        case "GET_PLAYER_INFO_ERROR": {
          // actually it is a token error
          // dispatch(showError('TOKEN', 'NOT_AUTHORIZED'))
          dispatch(showModal("ERROR", { errMSG: "INVALID_ARGUMENT" }));
          break;
        }
        default: {
          // unexpected error
          console.error(msg);
          dispatch(showModal("ERROR", { errMSG: "UNEXPECTED_ERROR" }));
          break;
        }
      }
      break;
    }
    case "REDEEM": {
      switch (msg) {
        case "ACTIVITY_CLOSED": {
          dispatch(
            showAlertModal({
              page: "profilePWD reset reset_fail",
              title: "redeem:popup_error_title",
              text: "redeem:popup_error_msg_activity_not_available",
            })
          );
          break;
        }
        case "INVALID_REDEEM_CODE": {
          dispatch(
            showAlertModal({
              page: "profilePWD reset reset_fail",
              title: "redeem:popup_error_title",
              text: "redeem:popup_error_msg_code_invalid",
            })
          );
          break;
        }
        case "CODE_USED_UP": {
          dispatch(
            showAlertModal({
              page: "profilePWD reset reset_fail",
              title: "redeem:popup_error_title",
              text: "redeem:popup_error_msg_code_used",
            })
          );
          break;
        }
        case "ALREADY_REWARDED": {
          dispatch(
            showAlertModal({
              page: "profilePWD reset reset_fail",
              title: "redeem:popup_error_title",
              text: "redeem:popup_error_msg_already_rewarded",
            })
          );
          break;
        }
        case "GET_PLAYER_INFO_ERROR":
        default: {
          dispatch(
            showAlertModal({
              page: "profilePWD reset reset_fail",
              title: "redeem:popup_error_title",
              text: "error:UNEXPECTED_ERROR",
            })
          );
          break;
        }
      }
      break;
    }
    default: {
      dispatch({
        type: RECEIVE_ERROR,
        key,
        msg,
      });
      break;
    }
  }
};

export const receiveError = (key, error) => async dispatch => {
  const ERR_TYPE = {
    RESPONSE_JSON: "ERR_RESPONSE_JSON",
    NETWORK: "ERR_NETWORK",
    TWITTER: "ERR_TWITTER",
    MSG: "ERR_MSG",
    UNKNOWN: "ERR_UNKNOWN",
  };

  const getErrorType = (_key, _error) => {
    return new Promise((resolve, _reject) => {
      if (_error.response) {
        _error.response
          .json()
          .then(json => {
            resolve({
              err_type: ERR_TYPE.RESPONSE_JSON,
              err_meta: json,
            });
          })
          .catch(_err_unexp => {
            // no json
            resolve({
              err_type: ERR_TYPE.NETWORK,
            });
          });
      } else if (_key === "twitterLogin") {
        resolve({ err_type: ERR_TYPE.TWITTER });
      } else if (_error.errMSG) {
        resolve({
          err_type: ERR_TYPE.MSG,
          err_meta: _error.errMSG,
        });
      } else {
        resolve({
          err_type: ERR_TYPE.UNKNOWN,
          err_meta: error,
        });
      }
    });
  };

  const { err_type, err_meta } = await getErrorType(key, error);

  switch (err_type) {
    case ERR_TYPE.RESPONSE_JSON: {
      if (
        err_meta.error &&
        (err_meta.error === "SESSION_DUPLICATION" || err_meta.error === "SESSION_EXPIRED")
      )
        dispatch(showError("TOKEN", err_meta.error));
      else if (err_meta.error) dispatch(showError(key, err_meta.error));
      else {
        // err_meta.error does not exist
        const is_token_sake =
          err_meta.description &&
          (err_meta.description.indexOf("SESSION_DUPLICATION") >= 0 ||
            err_meta.description.indexOf("SESSION_EXPIRED") >= 0);
        if (is_token_sake) {
          const which_token = ["SESSION_DUPLICATION", "SESSION_EXPIRED"].find(
            tok => err_meta.description.indexOf(tok) >= 0
          );
          dispatch(showError("TOKEN", which_token));
        } else {
          dispatch(showError(key, `Unexpected Error: ${error}`));
        }
      }
      break;
    }
    case ERR_TYPE.NETWORK: {
      dispatch(showError(key, `Network Error: ${error}`));
      break;
    }
    case ERR_TYPE.TWITTER: {
      dispatch(push("/sign-in-with-twitter?denied"));
      break;
    }
    case ERR_TYPE.MSG: {
      dispatch(showError(key, error.errMSG));
      break;
    }
    case ERR_TYPE.UNKNOWN:
    default: {
      dispatch(showError(key, "UNEXPECTED_ERROR"));
      console.error("receiveError", error);
      break;
    }
  }
  return;
};

export const clearError = () => dispatch => {
  dispatch(hideModal());
  dispatch({ type: CLEAR_ERROR });
};
