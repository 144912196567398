/*!(C)2019 Rayark Inc. - All Rights Reserved
 * Rayark Confidential
 *
 * NOTICE: The intellectual and technical concepts contained herein are
 * proprietary to or under control of Rayark Inc. and its affiliates.
 * The information herein may be covered by patents, patents in process,
 * and are protected by trade secret or copyright law.
 * You may not disseminate this information or reproduce this material
 * unless otherwise prior agreed by Rayark Inc. in writing.
 */

import React from "react";
import PropTypes from "prop-types";
import { LazyLoadImage } from "react-lazy-load-image-component";
import dayImg from "../../assets/images/ver_day.png";
import nightImg from "../../assets/images/ver_night.png";
import scrollImg from "../../assets/images/scrolldown.png";
import sysMSG_1 from "../../assets/images/sysMSG_1.png";
import intro_1 from "../../assets/images/intro_1.png";
import intro_rock from "../../assets/images/intro_rock.png";
import intro_chu from "../../assets/images/intro_chu.png";

import "./intro.scss";
function Intro({ t }) {
  let isNight = false;
  if (new Date().getHours() >= 18 || new Date().getHours() < 6) {
    isNight = true;
  }

  const timeClass = isNight ? "intro--night" : "intro--day";
  const renderMainImg = () => (
    <LazyLoadImage
      className="intro__mainPic"
      threshold={0}
      height={2477}
      src={isNight ? nightImg : dayImg}
    />
  );

  return (
    <div className="intro">
      <section className={`intro__content ${timeClass}`}>
        {renderMainImg()}
        <div className="intro__slogan">
          <div className="intro__sloganTitle">“ With Rayark Pass, “</div>
          <div className="intro--text_color_point">{t("index:slogan")}</div>
        </div>
        <div className="intro--text_size_min intro--text_color_point intro__scrollHint">
          Scroll down to see more info.
          <br />
          <img src={scrollImg} />
        </div>
      </section>

      <section className={`intro__mainImg ${timeClass}`}>
        <img className="intro__sysMSG" src={sysMSG_1} />
        {renderMainImg()}
        <div className="mobile intro__img--mob"></div>

        <div className="intro__msgBlock">
          {renderMainImg()}
          <div className="intro__message">
            <img src={intro_1} />
            <div className="intro__msgTitle">{t("index:intro_1_title")}</div>
            <div className="intro__msgContent">{t("index:intro_1_content")}</div>
          </div>
          <div className="intro__message">
            <img src={intro_rock} />
            <div className="intro__msgTitle">{t("index:intro_rock_title")}</div>
            <div className="intro__msgContent">{t("index:intro_rock_content")}</div>
          </div>
          <div className="intro__message">
            <img src={intro_chu} />
            <div className="intro__msgTitle">{t("index:intro_chu_title")}</div>
            <div className="intro__msgContent">{t("index:intro_chu_content")}</div>
          </div>
        </div>
      </section>
    </div>
  );
}

Intro.propTypes = {
  t: PropTypes.func,
};

export default Intro;
