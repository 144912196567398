/*!(C)2019 Rayark Inc. - All Rights Reserved
 * Rayark Confidential
 *
 * NOTICE: The intellectual and technical concepts contained herein are
 * proprietary to or under control of Rayark Inc. and its affiliates.
 * The information herein may be covered by patents, patents in process,
 * and are protected by trade secret or copyright law.
 * You may not disseminate this information or reproduce this material
 * unless otherwise prior agreed by Rayark Inc. in writing.
 */

import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { hideModal } from "../../actions/modal";

class AlertInnerModal extends React.Component {
  handleClose() {
    const { dispatch, page, history } = this.props;

    if (page.indexOf("profilePWD") <= -1 && page.indexOf("reset_fail") <= -1) {
      history.push("/login");
    } else {
      this._clearInput();
    }

    dispatch(hideModal());
  }

  _clearInput() {
    const resetDom = document.getElementById("reset_password");
    if (!resetDom) {
      return;
    }
    let dom = resetDom.getElementsByTagName("input");
    let resultMSGDom = resetDom.getElementsByClassName("resultMSG");

    for (let i in dom) {
      if (dom[i].value) {
        dom[i].value = "";
      }
    }

    for (let j in resultMSGDom) {
      if (resultMSGDom[j].classList) {
        resultMSGDom[j].classList.remove("success");
      }
    }
  }

  render() {
    const { t, page, title, text } = this.props;

    return (
      <div className={"resultContent " + page}>
        <div className="title">{t(title)}</div>
        <div className="describe" dangerouslySetInnerHTML={{ __html: t(text) }} />
        <div className="action">
          <div className="button" onClick={this.handleClose.bind(this)}>
            {t("form:ok")}
          </div>
        </div>
      </div>
    );
  }
}

AlertInnerModal.propTypes = {
  t: PropTypes.func,
  dispatch: PropTypes.func,
  history: PropTypes.object,
  page: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
};

export default withRouter(AlertInnerModal);
