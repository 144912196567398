import React from "react";
import PropTypes from "prop-types";
import { GeneralError } from "../components";
import { withTranslation } from "react-i18next";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError(_error) {
    return {
      hasError: true,
    };
  }

  render() {
    if (this.state.hasError) {
      return <GeneralError t={this.props.t} />;
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  t: PropTypes.func,
  children: PropTypes.node,
};

export default withTranslation()(ErrorBoundary);
