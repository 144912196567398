/*!(C)2019 Rayark Inc. - All Rights Reserved
 * Rayark Confidential
 *
 * NOTICE: The intellectual and technical concepts contained herein are
 * proprietary to or under control of Rayark Inc. and its affiliates.
 * The information herein may be covered by patents, patents in process,
 * and are protected by trade secret or copyright law.
 * You may not disseminate this information or reproduce this material
 * unless otherwise prior agreed by Rayark Inc. in writing.
 */

import React from "react";
import PropTypes from "prop-types";
import { LeftNavPanel } from "../../components";
import MyGamesRecordsView from "./MyGamesRecordsView";
import { PAGES } from "../../model/constants";
import icon_my_games from "../../assets/images/icon_following.png";
import icon_my_games_on from "../../assets/images/icon_following_on.png";

function MyGamesSegment({ t, i18n, dispatch, app, redeem }) {
  const getNavs = () => {
    const NAVS = [
      {
        key: PAGES.MY_GAMES_RECORDS,
        name: "My Games",
        mobile_name: "My Games with Rayark Pass",
        icon: {
          on: icon_my_games_on,
          off: icon_my_games,
        },
      },
    ];

    return NAVS;
  };

  return (
    <div className="myGameSegment">
      {!!app.segment && !!app.page ? (
        <LeftNavPanel
          navs={getNavs()}
          active_key={app.page}
          show_badge={false}
          small_icon={true}
          show_icon={false}>
          <MyGamesRecordsView
            t={t}
            i18n={i18n}
            dispatch={dispatch}
            page={app.page}
            redeem={redeem}
          />
        </LeftNavPanel>
      ) : null}
    </div>
  );
}

MyGamesSegment.propTypes = {
  t: PropTypes.func,
  i18n: PropTypes.object,
  dispatch: PropTypes.func,
  app: PropTypes.object,
  redeem: PropTypes.object,
};

export default MyGamesSegment;
