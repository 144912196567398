/*!(C)2019 Rayark Inc. - All Rights Reserved
 * Rayark Confidential
 *
 * NOTICE: The intellectual and technical concepts contained herein are
 * proprietary to or under control of Rayark Inc. and its affiliates.
 * The information herein may be covered by patents, patents in process,
 * and are protected by trade secret or copyright law.
 * You may not disseminate this information or reproduce this material
 * unless otherwise prior agreed by Rayark Inc. in writing.
 */

import { SEGMENTS } from "../model/constants";

export const UPDATE_CURRENT_PAGE = "UPDATE_CURRENT_PAGE";
export const UPDATE_CURRENT_SEGMENT = "UPDATE_CURRENT_SEGMENT";
export const UPDATE_CURRENT_SEGMENT_AND_PAGE = "UPDATE_CURRENT_SEGMENT_AND_PAGE";

const changeCurrentPage = page => dispatch => {
  dispatch({
    type: UPDATE_CURRENT_PAGE,
    page: page,
  });
};

// const changeCurrentSegment = (segment) => (dispatch) => {
//   dispatch({
//     type: UPDATE_CURRENT_SEGMENT,
//     segment: segment
//   })
// }

const changeCurrentSegmentAndPage = (segment, page) => dispatch => {
  dispatch({
    type: UPDATE_CURRENT_SEGMENT_AND_PAGE,
    segment: segment,
    page: page,
  });
};

export const updateCurrentPage = page => dispatch => {
  dispatch(changeCurrentPage(page));
};

export const updateCurrentSegment = segment => dispatch => {
  const segment_found = SEGMENTS.find(seg => seg.key === segment);
  if (segment_found) {
    dispatch(changeCurrentSegmentAndPage(segment_found.key, segment_found.default_page));
  }
};
