/*!(C)2019 Rayark Inc. - All Rights Reserved
 * Rayark Confidential
 *
 * NOTICE: The intellectual and technical concepts contained herein are
 * proprietary to or under control of Rayark Inc. and its affiliates.
 * The information herein may be covered by patents, patents in process,
 * and are protected by trade secret or copyright law.
 * You may not disseminate this information or reproduce this material
 * unless otherwise prior agreed by Rayark Inc. in writing.
 */

import React from "react";
import PropTypes from "prop-types";
import { BackTopBtn } from "../../layouts";
import faqTitleImg from "../../assets/images/faq_title.png";
import envelopeIcon from "../../assets/images/envelope.png";

import "./faq.scss";

const FAQ_COUNT = 7;

function FAQ({ t }) {
  const renderFAQContent = () => {
    return (
      <div className="faq__content">
        {[...Array(FAQ_COUNT).keys()].map(idx => {
          const index = idx + 1;
          let answer = t(`faq:a${index}`);
          answer = answer.replace(/</g, "&lt;");
          answer = answer.replace(/>/g, "&gt;");
          answer = answer.replace(/(?:\r\n|\r|\n)/g, "<br />");

          return (
            <div className="faq__block" key={`faq_${index}`}>
              <div className="faq__question">
                <span>{`Q${index}: ${t(`faq:q${index}`)}`}</span>
              </div>
              <div className="faq__answer">
                {answer.split("<br />").map((text, i) => (
                  <div key={`text_${i}`}>{text}</div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="faq">
      <BackTopBtn setClass="faq__backTopBtn--putTop" />
      <div className="faq__title">
        <img src={faqTitleImg} />
      </div>
      {renderFAQContent()}
      <div className="faq__mail">
        <div className="faq__mailHint">Still have question?</div>
        <div className="faq__mailBtn">
          <a href="mailto:service@rayark.com" />
          <img src={envelopeIcon} />
          <span> Mail us now! </span>
        </div>
      </div>
      <BackTopBtn setClass="faq__backTopBtn--putBottom" />
    </div>
  );
}

FAQ.propTypes = {
  t: PropTypes.func,
};

export default FAQ;
