/*!(C)2019 Rayark Inc. - All Rights Reserved
 * Rayark Confidential
 *
 * NOTICE: The intellectual and technical concepts contained herein are
 * proprietary to or under control of Rayark Inc. and its affiliates.
 * The information herein may be covered by patents, patents in process,
 * and are protected by trade secret or copyright law.
 * You may not disseminate this information or reproduce this material
 * unless otherwise prior agreed by Rayark Inc. in writing.
 */

/**
 * Handler for Fetch API to check status. Throw if the status is not between
 * 200-299
 * @param {Response} response Fetch API response.
 * @return {Response} Same as input param if successful.
 */
export function checkStatus(response) {
  if (response.status < 200 || response.status >= 300) {
    let error = new Error(response.statusText);
    error.response = response;
    throw error;
  }

  return response;
}

export function returnStatusPromise(response) {
  return new Promise((resolve, reject) => {
    if (response.status < 200 || response.status >= 300) {
      parseJSON()
        .then(json => {
          reject({ json });
        })
        .catch(e => {
          reject(e);
        });
    } else {
      resolve(response);
    }
  });
}

function toURLEncoded(data) {
  return Object.keys(data)
    .map(key => `${key}=${encodeURIComponent(data[key])}`)
    .join("&");
}

export function toQueryString(data) {
  return data ? `?${toURLEncoded(data)}` : "";
}

/**
 * Handler for Fetch API to parse JSON content.
 * @param {Response} response Fetch API response.
 * @return {Object} Parsed JSON object.
 */

export function parseQuery(query_string) {
  const decode_string = decodeURI(query_string);
  return decode_string.split("?").length > 1
    ? decode_string
        .split("?")[1]
        .split("&")
        .map(pair_string => {
          const [key, name] = pair_string.split("=");
          return { [key]: name };
        })
        .reduce((l, r) => ({ ...l, ...r }), {})
    : {};
}

export function parseJSON(response) {
  // Since response body could be empty and will cause "parse error" on response.json(),
  // turn response to text first and then parse it as JSON if text is not empty.
  return response
    .text()
    .then(text => (text.length ? JSON.parse(text) : {}))
    .catch(error => {
      throw error;
    });
}

export function getData(url, data = {}, customHeaders = null, customOptions = {}) {
  const options = Object.assign(
    { method: "GET" },
    customHeaders ? { headers: new Headers(customHeaders) } : {},
    ...customOptions
  );

  const params = toQueryString(data);
  return fetch(`${url}${params}`, options);
}

export function postData(url, data = {}, customHeaders = {}, customOptions = {}) {
  const headers = new Headers({
    "Content-Type": "application/json",
    ...customHeaders,
  });

  const options = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(data),
    ...customOptions,
  };

  return fetch(url, options);
}

export function getDataWithHeaderToken(url) {
  let headers = new Headers();
  const accessToken = window.localStorage.getItem("accessToken");
  if (accessToken) {
    headers.append("Access-Token", accessToken);
  }

  return fetch(url, {
    method: "GET",
    headers: headers,
  });
}

function fetchWithHeaderToken(method, url, data = {}, customHeaders = {}, customOptions = {}) {
  let headers = new Headers({
    "Content-Type": "application/json",
    ...customHeaders,
  });

  const accessToken = window.localStorage.getItem("accessToken");
  if (accessToken) {
    headers.append("Access-Token", accessToken);
  }

  return fetch(url, {
    method: method,
    headers: headers,
    body: JSON.stringify(data),
    ...customOptions,
  });
}

export function postDataWithHeaderToken(url, data, customHeaders = {}, customOptions = {}) {
  return fetchWithHeaderToken("POST", url, data, customHeaders, customOptions);
}

export function patchDataWithHeaderToken(url, data, customHeaders = {}, customOptions = {}) {
  return fetchWithHeaderToken("PATCH", url, data, customHeaders, customOptions);
}

export function putDataWithHeaderToken(url, data, customHeaders = {}, customOptions = {}) {
  return fetchWithHeaderToken("PUT", url, data, customHeaders, customOptions);
}

export function deleteDataWithHeaderToken(url, data, customHeaders = {}, customOptions = {}) {
  return fetchWithHeaderToken("DELETE", url, data, customHeaders, customOptions);
}

// Send form with encoded key-value pairs (key1=val1&key2=val2)
export function postFormData(url, data) {
  let headers = new Headers();
  headers.append("Content-Type", "application/x-www-form-urlencoded");

  return fetch(url, {
    method: "POST",
    headers: headers,
    body: toURLEncoded(data),
    credentials: "include",
  });
}

// For redirecting to other pages, such as OAuth2
export function postFormTo(url, data) {
  let form = document.createElement("form");
  form.method = "POST";
  form.action = url;

  Object.keys(data).forEach(key => {
    let hidden_field = document.createElement("input");
    hidden_field.type = "hidden";
    hidden_field.name = key;
    hidden_field.value = data[key];
    form.appendChild(hidden_field);
  });

  document.body.appendChild(form);
  form.submit();
}
