/*!(C)2019 Rayark Inc. - All Rights Reserved
 * Rayark Confidential
 *
 * NOTICE: The intellectual and technical concepts contained herein are
 * proprietary to or under control of Rayark Inc. and its affiliates.
 * The information herein may be covered by patents, patents in process,
 * and are protected by trade secret or copyright law.
 * You may not disseminate this information or reproduce this material
 * unless otherwise prior agreed by Rayark Inc. in writing.
 */

import React from "react";
import PropTypes from "prop-types";
import { hideModal } from "../../actions/modal";
import { updateSID } from "../../actions/profile";

function SIDInnerModal({ t, dispatch, sid, errMSG }) {
  const handleUpdateSID = () => {
    dispatch(updateSID(sid));
  };

  const handleClose = () => {
    dispatch(hideModal());
  };

  return (
    <div className="resultContent updateSID">
      <div className="title">{t("profile:setting_SID_title")}</div>
      {errMSG ? (
        <>
          <div className="describe">
            <div>{t(`error:${errMSG}`)}</div>
          </div>
          <div className="action">
            <div className="button subBtn" onClick={handleClose}>
              {t("form:ok")}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="describe">
            <div>{t("profile:setting_sid_confirm")}</div>
            <div>{sid}</div>
          </div>
          <div className="action">
            <div className="button" onClick={handleUpdateSID}>
              {t("form:ok")}
            </div>
            <div className="button subBtn marginBtn" onClick={handleClose}>
              {t("form:cancel")}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

SIDInnerModal.propTypes = {
  t: PropTypes.func,
  dispatch: PropTypes.func,
  sid: PropTypes.string,
  errMSG: PropTypes.string,
};

export default SIDInnerModal;
