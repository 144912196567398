/*!(C)2019 Rayark Inc. - All Rights Reserved
 * Rayark Confidential
 *
 * NOTICE: The intellectual and technical concepts contained herein are
 * proprietary to or under control of Rayark Inc. and its affiliates.
 * The information herein may be covered by patents, patents in process,
 * and are protected by trade secret or copyright law.
 * You may not disseminate this information or reproduce this material
 * unless otherwise prior agreed by Rayark Inc. in writing.
 */

import React from "react";
import PropTypes from "prop-types";
import { ViewContainer } from "../../components";
import FollowList from "./FollowList";
import * as friendActions from "../../actions/friends";
import * as modalActions from "../../actions/modal";
import { PAGES, FRIENDS_TAB } from "../../model/constants";
import FontAwesome from "react-fontawesome";

class FriendView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: "",
    };
  }

  componentDidMount() {
    this.props.dispatch(friendActions.fetchFriendSegmentData());
  }

  handleSearch() {
    const sid = document.getElementById("search_sid").value;
    if (!sid) {
      return;
    }
    this.props.dispatch(friendActions.searchUser(sid));
  }

  handleSearchKeyPress(e) {
    if (e.key === "Enter") {
      this.handleSearch();
    }
  }

  unfollow(player) {
    this.setState({ selected: "" });
    this.props.dispatch(modalActions.showUnfollowModal(player));
  }

  addFollower(player) {
    if (!player.sid) {
      return;
    }
    this.setState({ selected: "" });
    this.props.dispatch(friendActions.searchUser(player.sid));
  }

  // for mobile
  onFocus(sid, e) {
    if (e.target.className === "unfollow" || e.target.className === "addFollower") {
      return;
    }

    if (this.state.selected === sid) {
      this.setState({ selected: "" });
    } else {
      this.setState({ selected: sid });
    }
  }

  onHover(sid, hover, e) {
    let userAgent = String(window.navigator.userAgent);
    userAgent = userAgent.toLowerCase();
    if (userAgent.indexOf("mobile") > 0) {
      return;
    }

    if (e.target.className === "unfollow" || e.target.className === "addFollower") {
      return;
    }

    if (!hover) {
      this.setState({ selected: "" });
    } else {
      this.setState({ selected: sid });
    }
  }

  renderFollowingList() {
    return (
      <FollowList
        friends={this.props.friends}
        selected={this.state.selected}
        target={FRIENDS_TAB.FOLLOWING}
        prefix={FRIENDS_TAB.FOLLOWING}
        title="Following List"
        onClick={sid => this.onFocus.bind(this, sid)}
        onHoverIn={sid => this.onHover.bind(this, sid, true)}
        onHoverOut={sid => this.onHover.bind(this, sid, false)}
        onClickUnfollow={player => this.unfollow.bind(this, player)}>
        <div className="searchBar pc">
          <input
            type="search"
            id="search_sid"
            placeholder={this.props.t("profile:search_placeholder")}
            name="sid"
            onKeyPress={this.handleSearchKeyPress.bind(this)}
          />
          <FontAwesome name="search" onClick={this.handleSearch.bind(this)} />
        </div>
      </FollowList>
    );
  }

  renderFollowersList() {
    return (
      <FollowList
        friends={this.props.friends}
        selected={this.state.selected}
        target={FRIENDS_TAB.FOLLOWERS}
        prefix={FRIENDS_TAB.FOLLOWERS}
        title="Followers List"
        onClick={sid => this.onFocus.bind(this, sid)}
        onHoverIn={sid => this.onHover.bind(this, sid, true)}
        onHoverOut={sid => this.onHover.bind(this, sid, false)}
        onClickAddUser={player => this.addFollower.bind(this, player)}
      />
    );
  }

  render() {
    const { friends, page } = this.props;

    switch (page) {
      case PAGES.FOLLOWERS:
        return friends.followers && this.renderFollowersList();
      case PAGES.FOLLOWING:
        return friends.following && this.renderFollowingList();
      default:
        return <ViewContainer> no such view </ViewContainer>;
    }
  }
}

FriendView.propTypes = {
  t: PropTypes.func,
  dispatch: PropTypes.func,
  page: PropTypes.string,
  profile: PropTypes.object,
  friends: PropTypes.object,
};

export default FriendView;
