/*!(C)2019 Rayark Inc. - All Rights Reserved
 * Rayark Confidential
 *
 * NOTICE: The intellectual and technical concepts contained herein are
 * proprietary to or under control of Rayark Inc. and its affiliates.
 * The information herein may be covered by patents, patents in process,
 * and are protected by trade secret or copyright law.
 * You may not disseminate this information or reproduce this material
 * unless otherwise prior agreed by Rayark Inc. in writing.
 */

import React from "react";

// independent routes
const RayarkAuth = React.lazy(() => import("../containers/RayarkAuth"));
const ErrorPage = React.lazy(() => import("../containers/ErrorPage"));

// subpages of rayark pass
const Index = React.lazy(() => import("../containers/Index"));
const Login = React.lazy(() => import("../containers/Login"));
const Logout = React.lazy(() => import("../containers/Logout"));
const Register = React.lazy(() => import("../containers/Register"));
const Forgot = React.lazy(() => import("../containers/Forgot"));
const ResetPassword = React.lazy(() => import("../containers/ResetPassword")); // only from email content
const Verify = React.lazy(() => import("../containers/Verify"));
const Waiting = React.lazy(() => import("../containers/Waiting"));
const Admin = React.lazy(() => import("../containers/Admin"));
const Setting = React.lazy(() => import("../containers/Setting"));

export const INDEPENDENT_ROUTES = [
  // routes for serving RP OAuth
  { exact: true, path: "/auth", component: RayarkAuth },
  { exact: true, path: "/auth_continue", component: RayarkAuth },
  { exact: true, path: "/error", component: ErrorPage },
];

export const APP_ROUTES = [
  // Subs to rayark pass
  { exact: true, path: "/", component: Index },
  { path: "/login", component: Login },
  { path: "/logout", component: Logout },
  { path: "/register", component: Register },
  { path: "/register/mobile", component: Register }, // for rayark pass client to register by email
  { path: "/waiting", component: Waiting },
  { path: "/forgot", component: Forgot },
  { path: "/reset_password", component: ResetPassword },
  { path: "/verify", component: Verify },
  { path: "/profile", component: Admin },
  { path: "/redeem", component: Admin },
  { path: "/my-games", component: Admin },
  { path: "/setting", component: Setting },
];
