/*!(C)2019 Rayark Inc. - All Rights Reserved
 * Rayark Confidential
 *
 * NOTICE: The intellectual and technical concepts contained herein are
 * proprietary to or under control of Rayark Inc. and its affiliates.
 * The information herein may be covered by patents, patents in process,
 * and are protected by trade secret or copyright law.
 * You may not disseminate this information or reproduce this material
 * unless otherwise prior agreed by Rayark Inc. in writing.
 */

import URLSearchParams from "core-js/features/url-search-params";
import config from "../variables";
import {
  checkStatus,
  returnStatusPromise,
  parseJSON,
  getDataWithHeaderToken,
  postFormData,
  postFormTo,
} from "../model/network";

export const SET_AUTH_PROVIDE_MODE = "SET_AUTH_PROVIDE_MODE";
export const UNSET_AUTH_PROVIDE_MODE = "UNSET_AUTH_PROVIDE_MODE";

const _redirectToDefaultRoute = () => {
  window.location.href = window.location.origin + "/my-games";
};

// Check the validity of the access token.
// After sending POST /logout, use GET /service/session to check if a token has been revoked.
export const _checkAccessTokenInSession = access_token => {
  return new Promise((resolve, reject) => {
    if (!access_token) {
      reject(new Error("no access token"));
    } else {
      getDataWithHeaderToken(`${config.serverUrl}/service/session`, access_token)
        .then(checkStatus)
        .then(() => {
          resolve();
        })
        .catch(() => {
          reject(new Error("token expired"));
        });
    }
  });
};

// TODO: polyfill URLSearchParams
// Handle redirection from event pages.
// Get data from URL and POST a form to /oauth/authorize, which invokes a redirection.
export const initiateOAuth = () => dispatch => {
  const urlSearchParams = new URLSearchParams(window.location.search);

  const params = {
    client_id: urlSearchParams.get("client_id"),
    redirect_uri: urlSearchParams.get("redirect_uri"),
    response_type: urlSearchParams.get("response_type"),
    state: urlSearchParams.get("state"),
    code_challenge: urlSearchParams.get("code_challenge"),
    code_challenge_method: urlSearchParams.get("code_challenge_method"),
    access_token: window.localStorage.getItem("accessToken"),
  };

  window.sessionStorage.clear();
  dispatch(setValProvideMode(true)); // set provide mode

  const refForRegister = {};

  // ===== work around begin =====
  // store query string params(ref params) in sessionStorage
  // It is used for OAuth request with email authentication
  Object.keys(params)
    .filter(key => key !== "access_token")
    .forEach(key => {
      window.sessionStorage.setItem(key, params[key]);
      refForRegister[key] = params[key];
    });
  // ===== work around end =====

  if (urlSearchParams.get("register")) {
    // ===== work around begin =====
    // if register, create ref by ourself and redirect to register page
    window.sessionStorage.setItem("ref", JSON.stringify(refForRegister));
    window.location.href = `${window.location.origin}/register`;
    // ===== work around end =====
  } else {
    // POST a form to the authorization endpoint of Phoenix.
    // If succeeds, Phoenix will redirect UA to /auth_continue?ref=..., where continueAuth do its job.
    // If fails, Phoenix will redirect UA to /login?ref=...
    postFormTo(`${config.serverUrl}/oauth/authorize`, params);
  }
};

// The main purpose for this function is to save ref in sessionStorage for sharing data among handlers.
export const setValProvideMode = setVal => () => dispatch => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  if (urlSearchParams.has("ref")) {
    const ref = urlSearchParams.get("ref");
    if (setVal) {
      window.sessionStorage.setItem("auth_provide_mode", true);
      window.sessionStorage.setItem("ref", ref);
      dispatch({ type: SET_AUTH_PROVIDE_MODE });
    } else {
      window.sessionStorage.removeItem("auth_provide_mode");
      window.sessionStorage.removeItem("ref");
      dispatch({ type: UNSET_AUTH_PROVIDE_MODE });
    }
  }
};

export const continueAuth = ({ oauth_mode }) => dispatch => {
  const urlSearchParams = new URLSearchParams(window.location.search);

  if (urlSearchParams.has("code") && urlSearchParams.get("code") !== "dummy") {
    dispatch(_continueAuth_withURLParams({ oauth_mode }));
  } else if (oauth_mode) {
    // The only case reaching here is OAuth requested via email authentication.
    dispatch(setValProvideMode(false)()); // unset provide mode

    // ===== work around begin =====
    // Extract OAuth parameters of event pages from sessionStorage,
    const keys = [
      "client_id",
      "redirect_uri",
      "response_type",
      "state",
      "code_challenge",
      "code_challenge_method",
    ];
    const params = keys
      .map(key => ({ [key]: window.sessionStorage.getItem(key) }))
      .reduce((l, r) => ({ ...l, ...r }), {});
    keys.forEach(key => {
      window.sessionStorage.removeItem(key);
    });

    // POST form to /oauth/authorize again to continue OAuth.
    postFormTo(`${config.serverUrl}/oauth/authorize`, {
      ...params,
      access_token: window.localStorage.getItem("accessToken"),
    });
    // ===== work around end =====
  } else {
    // This is not a provided mode, it should not be here.
    _redirectToDefaultRoute();
  }
};

const _exchangeCodeForToken = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const code = urlSearchParams.get("code");
  const code_verifier = window.sessionStorage.getItem("rp_cv");
  window.sessionStorage.removeItem("rp_cv");

  const data = {
    client_id: config.rayark_appkey, // TODO: maybe we should use client_id from event pages or Rayark Pass Web itself
    grant_type: "authorization_code",
    code: code,
    code_verifier: code_verifier,
  };

  return postFormData(`${config.serverUrl}/oauth/token`, data);
};

export const _continueAuth_withURLParams = ({ oauth_mode }) => dispatch => {
  if (oauth_mode) {
    dispatch(setValProvideMode(false)()); // unset provide mode
    // Exchange code for access token
    _exchangeCodeForToken()
      .then(returnStatusPromise)
      .then(parseJSON)
      .then(json => {
        // Exchange token successfully!
        // POST /oauth/authorize again to continue OAuth.
        const urlSearchParams = new URLSearchParams(window.location.search);
        const params = {
          client_id: urlSearchParams.get("client_id"),
          redirect_uri: urlSearchParams.get("redirect_uri"),
          response_type: urlSearchParams.get("response_type"),
          state: urlSearchParams.get("state"),
          code_challenge: urlSearchParams.get("code_challenge"),
          code_challenge_method: urlSearchParams.get("code_challenge_method"),
          access_token: json.access_token,
        };
        window.localStorage.setItem("accessToken", json.access_token);
        postFormTo(`${config.serverUrl}/oauth/authorize`, params);
      })
      .catch(error => {
        if (error.json) {
          console.error("error", error.json.error);
          console.error("description", error.json.description);
        } else {
          console.error("error", "FETCH_ERROR");
          console.error("description", "No description.");
        }
        window.location.href = `${window.location.origin}/error`;
      });
  } else {
    // This is not a provided mode, it should not be here.
    _exchangeCodeForToken()
      .then(returnStatusPromise)
      .then(parseJSON)
      .then(json => {
        // Exchange token successfully!
        // Redirect to the default route
        window.localStorage.setItem("accessToken", json.access_token);
        _redirectToDefaultRoute();
      })
      .catch(error => {
        if (error.json) {
          console.error("error", error.json.error);
          console.error("description", error.json.description);
        } else {
          console.error("error", "FETCH_ERROR");
          console.error("description", "No description.");
        }
        window.location.href = `${window.location.origin}/error`;
      });
  }
};

export const setOAuthMode = setValProvideMode(true);
export const unsetOAuthMode = setValProvideMode(false);
