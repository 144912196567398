/*!(C)2019 Rayark Inc. - All Rights Reserved
 * Rayark Confidential
 *
 * NOTICE: The intellectual and technical concepts contained herein are
 * proprietary to or under control of Rayark Inc. and its affiliates.
 * The information herein may be covered by patents, patents in process,
 * and are protected by trade secret or copyright law.
 * You may not disseminate this information or reproduce this material
 * unless otherwise prior agreed by Rayark Inc. in writing.
 */

import React from "react";
import PropTypes from "prop-types";
import { hideModal } from "../../actions/modal";

class CloneDoneModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opacity: 0,
    };
    this.fields = {};
  }

  componentDidMount() {
    let dom = this.fields.SID_copied;
    dom.classList.add("show");
    if (!this.timeout) {
      clearTimeout(this.timeout);
    }

    this.timeout = setTimeout(() => {
      dom.classList.remove("show");
      this.close();
    }, 1000);
  }

  close() {
    const { dispatch } = this.props;
    setTimeout(() => dispatch(hideModal()), 400);
  }

  render() {
    const { t } = this.props;

    return (
      <div className="resultContent SID_copied" ref={ref => (this.fields.SID_copied = ref)}>
        <div>{t("profile:SID_copied")}</div>
      </div>
    );
  }
}

CloneDoneModal.propTypes = {
  t: PropTypes.func,
  dispatch: PropTypes.func,
};

export default CloneDoneModal;
