/*!(C)2019 Rayark Inc. - All Rights Reserved
 * Rayark Confidential
 *
 * NOTICE: The intellectual and technical concepts contained herein are
 * proprietary to or under control of Rayark Inc. and its affiliates.
 * The information herein may be covered by patents, patents in process,
 * and are protected by trade secret or copyright law.
 * You may not disseminate this information or reproduce this material
 * unless otherwise prior agreed by Rayark Inc. in writing.
 */

import React from "react";
import PropTypes from "prop-types";
import { UserSID, Avatar } from "../../components";
import { unfollow } from "../../actions/friends";
import { hideModal } from "../../actions/modal";

class ConfirmUnfollowInnerModal extends React.Component {
  handleUnfollow() {
    const { dispatch, player } = this.props;
    dispatch(unfollow(player));
    this.handleClose();
  }

  handleClose() {
    const { dispatch } = this.props;
    let box = document.getElementById("admin__leftNavPanel");
    box.classList.remove("modalShowing");
    dispatch(hideModal());
  }

  render() {
    const { t, player } = this.props;
    let box = document.getElementById("admin__leftNavPanel");
    box.classList.add("modalShowing");

    return (
      <div className="resultBox">
        <div className="resultBoxBack">
          <div className="resultContent result_Unfo">
            <div className="sid_result">
              <Avatar avatarURL={player.avatar} />
              <UserSID SID={player.sid} />
            </div>
            <div className="resultMSG">{t("profile:del_confirm")}</div>
            <div className="action">
              <div className="button delUser" onClick={this.handleUnfollow.bind(this)}>
                {t("profile:delete")}
              </div>
              <div className="button subBtn marginBtn" onClick={this.handleClose.bind(this)}>
                {t("form:cancel")}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ConfirmUnfollowInnerModal.propTypes = {
  t: PropTypes.func,
  dispatch: PropTypes.func,
  player: PropTypes.object,
};

export default ConfirmUnfollowInnerModal;
