/*!(C)2021 Rayark Inc. - All Rights Reserved
 * Rayark Confidential
 *
 * NOTICE: The intellectual and technical concepts contained herein are
 * proprietary to or under control of Rayark Inc. and its affiliates.
 * The information herein may be covered by patents, patents in process,
 * and are protected by trade secret or copyright law.
 * You may not disseminate this information or reproduce this material
 * unless otherwise prior agreed by Rayark Inc. in writing.
 */

import React from "react";
import PropTypes from "prop-types";

const ResultMessage = ({ status = "", message = "" }) => {
  return (
    <div className={`resultMSG ${status}`}>
      <span className="errMSG pc">{message}</span>
    </div>
  );
};

ResultMessage.propTypes = {
  status: PropTypes.string,
  message: PropTypes.string,
};

export default ResultMessage;
