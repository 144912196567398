/*!(C)2019 Rayark Inc. - All Rights Reserved
 * Rayark Confidential
 *
 * NOTICE: The intellectual and technical concepts contained herein are
 * proprietary to or under control of Rayark Inc. and its affiliates.
 * The information herein may be covered by patents, patents in process,
 * and are protected by trade secret or copyright law.
 * You may not disseminate this information or reproduce this material
 * unless otherwise prior agreed by Rayark Inc. in writing.
 */

import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import history from "../history";
import { routerMiddleware } from "react-router-redux";
import { composeWithDevTools } from "redux-devtools-extension";
// import { syncHistoryWithStore } from 'react-router-redux';
// Note: according to document of react-router-redux,
// (https://github.com/reactjs/react-router-redux/issues/581)
// routerMiddleware and syncHistoryWithStore are not possible to work together
import rootReducer from "../reducers";

function configureStore(initialState, _history) {
  const routerhistory = _history ? _history : history;
  const middleware = [routerMiddleware(routerhistory), thunk];

  const store = createStore(
    rootReducer,
    initialState,
    process.env.NODE_ENV === "development"
      ? composeWithDevTools(
          applyMiddleware(...middleware)
          // other store enhancers if any
        )
      : applyMiddleware(...middleware)
  );

  // https://redux.js.org/api/store#replacereducernextreducer
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept("../reducers", () => {
      const nextReducer = require("../reducers/index");
      store.replaceReducer(nextReducer);
    });
  }

  return store;
}

// create store here to prevent to generate a new store instance when hot reloading
const store = configureStore();

export default store;
