/*!(C)2019 Rayark Inc. - All Rights Reserved
 * Rayark Confidential
 *
 * NOTICE: The intellectual and technical concepts contained herein are
 * proprietary to or under control of Rayark Inc. and its affiliates.
 * The information herein may be covered by patents, patents in process,
 * and are protected by trade secret or copyright law.
 * You may not disseminate this information or reproduce this material
 * unless otherwise prior agreed by Rayark Inc. in writing.
 */

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

class ErrorMessage extends React.Component {
  render() {
    const { t, error, messageKey } = this.props;
    let msg = error[messageKey];
    if (msg) {
      if (messageKey === "login" && msg === "AUTHENTICATION_FAILED") {
        msg = "error:LOGIN_AUTHENTICATION_FAILED";
      } else {
        msg = "error:" + msg;
      }
      return <div className="errMSG">{t(msg)}</div>;
    }
    return <div className="errMSG hideErr"></div>;
  }
}

function mapStateToProps(state) {
  return {
    error: state.error,
  };
}

ErrorMessage.propTypes = {
  t: PropTypes.func,
  error: PropTypes.object,
  messageKey: PropTypes.string,
};

export default connect(mapStateToProps)(withTranslation()(ErrorMessage));
