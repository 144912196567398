/*!(C)2019 Rayark Inc. - All Rights Reserved
 * Rayark Confidential
 *
 * NOTICE: The intellectual and technical concepts contained herein are
 * proprietary to or under control of Rayark Inc. and its affiliates.
 * The information herein may be covered by patents, patents in process,
 * and are protected by trade secret or copyright law.
 * You may not disseminate this information or reproduce this material
 * unless otherwise prior agreed by Rayark Inc. in writing.
 */

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { showCloneDoneModal } from "../../actions/modal";
import btn_clone from "../../assets/images/btn_clone.png";
import ClipboardButton from "react-clipboard.js";

import "./buttons.scss";

class CloneBtn extends React.Component {
  cloneDone() {
    const { dispatch } = this.props;
    dispatch(showCloneDoneModal());
  }

  render() {
    const { profile } = this.props;
    let mySID = profile ? profile.sid : "";

    return (
      <div className="cloneBtn button">
        <ClipboardButton data-clipboard-text={mySID} onSuccess={this.cloneDone.bind(this)}>
          <img src={btn_clone} />
        </ClipboardButton>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    profile: state.profile,
  };
}

CloneBtn.propTypes = {
  dispatch: PropTypes.func,
  profile: PropTypes.object,
};

export default connect(mapStateToProps)(withTranslation()(CloneBtn));
