/*!(C)2021 Rayark Inc. - All Rights Reserved
 * Rayark Confidential
 *
 * NOTICE: The intellectual and technical concepts contained herein are
 * proprietary to or under control of Rayark Inc. and its affiliates.
 * The information herein may be covered by patents, patents in process,
 * and are protected by trade secret or copyright law.
 * You may not disseminate this information or reproduce this material
 * unless otherwise prior agreed by Rayark Inc. in writing.
 */

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import * as profileActions from "../../actions/profile";
import {
  Dialog,
  DialogTitle,
  DialogSubtitle,
  DialogContent,
  DialogFooter,
  ResultMessage,
} from "../../layouts";

const SettingPassword = ({ t, dispatch }) => {
  const [oldPassword, updateOldPassword] = useState("");
  const [newPassword, updateNewPassword] = useState(null);
  const [newPasswordConfirm, updateNewPasswordConfirm] = useState(null);

  const checkLength = () => {
    return !!(newPassword && newPassword.length >= 6);
  };

  const checkConfirm = () => {
    return !!(newPasswordConfirm && newPassword === newPasswordConfirm);
  };

  const validateAll = () => {
    return checkLength() && checkConfirm();
  };

  const handleUpdatePassword = e => {
    e.preventDefault();

    if (validateAll()) {
      dispatch(profileActions.updatePWD(oldPassword, newPassword));
    }
  };

  const getCheckLengthStatus = () => {
    return newPassword === null ? "" : checkLength() ? "success" : "error";
  };

  const getCheckConfirmStatus = () => {
    return newPasswordConfirm === null ? "" : checkConfirm() ? "success" : "error";
  };

  const errorMessage = checkLength()
    ? t("form:password_rule")
    : checkConfirm()
    ? t("form:err_password_not_match")
    : "";

  return (
    <Dialog className="setting__settingBox setting_subPage">
      <DialogTitle mobileTitle={t("form:reset_success_title")} />
      <DialogSubtitle pcSubTitle={t("form:reset_success_title")} />
      <DialogContent className="setting__settingBlock resetDialog" id="reset_password">
        <div className="setting__item">
          <div className="setting__title">{t("form:enter_old_password")}</div>
          <input
            type="password"
            className="setting__input"
            onChange={e => updateOldPassword(e.target.value)}
            onBlur={validateAll}
          />
        </div>

        <br className="pc" />
        <br />

        <div className={`mobile errMSG ${errorMessage ? "" : "hideErr"}`}>{errorMessage}</div>

        <div className="setting__item">
          <div className="setting__title">{t("form:enter_new_password")}</div>
          <input
            type="password"
            className="setting__input"
            onChange={e => updateNewPassword(e.target.value)}
            onBlur={validateAll}
          />
          <ResultMessage status={getCheckLengthStatus()} message={t("form:password_rule")} />
        </div>

        <div className="setting__item">
          <div className="setting__title">{t("form:confirm_new_password")}</div>
          <input
            type="password"
            className="setting__input"
            onChange={e => updateNewPasswordConfirm(e.target.value)}
            onBlur={validateAll}
          />
          <ResultMessage
            status={getCheckConfirmStatus()}
            message={t("form:err_password_not_match")}
          />
        </div>
      </DialogContent>
      <DialogFooter>
        <div className="setting__buttonRow">
          <div className="button" onClick={handleUpdatePassword}>
            {t("form:ok")}
          </div>
          <Link to="/setting" className="button subBtn">
            {t("form:cancel")}
          </Link>
        </div>
      </DialogFooter>
    </Dialog>
  );
};

SettingPassword.propTypes = {
  t: PropTypes.func,
  dispatch: PropTypes.func,
};

export default SettingPassword;
